import React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useStore } from '../stores/branchStore';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'manrope',
    borderStyle: 'none',
  },
  test: {
    backgroundColor: 'pink'
  },
  root: {
    '& .super-app-theme--header': {
      fontSize: 16,
    },
  },
}));

const columns = [
  { 
    field: 'code', 
    headerName: 'Codigo', 
    flex: 1,
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip 
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'status', headerName: 'Estatus', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'name', headerName: 'Nombre', flex: 3, headerClassName: 'super-app-theme--header' },
  { field: 'services', headerName: 'Servicios', flex: 4, headerClassName: 'super-app-theme--header' },
  { field: 'clientName', headerName: 'Cliente (nombre comercial)', flex: 3, headerClassName: 'super-app-theme--header' },
];

const mobileColumns = [
  { 
    field: 'code',
    headerName: 'Codigo',
    flex: 2,
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip
        size='small'
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'name', headerName: 'Nombre', flex: 5, headerClassName: 'super-app-theme--header' },
];

const branchTable = observer(() => {
  
  const branchStore = useStore();
  let history = useHistory();
  const classes = useStyles();
  let rows = [];

  if (branchStore.branchSearch.results.length) {
    for (const branchDoc of branchStore.branchSearch.results) {
      let services = ''

      for (const service of branchDoc.services) {
        services += ` ● ${service.name} `;
      }

      let row = {
        id: branchDoc._id,
        code: `SD-${branchDoc.code || 1}`,
        name: branchDoc.name,
        clientName: branchDoc.client.tradeName,
        status: branchDoc.status,
        services: services,
      };
      rows.push(row);
    }
  }

  useEffect(async () => {
    await branchStore.getAll();
    //TODO this comment disables react linting, there is a problem here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>

    {/*Desktop grid*/}
    <Hidden smDown implementation="css">
      <DataGrid 
        rows={rows}
        columns={columns}
        direction="column"
        pageSize={20}
        density='standard' // or 'comfortable'
        autoHeight={true}
        localeText={esES.props.MuiDataGrid.localeText}
        className={classes.manrope}
        onRowClick={async (event) => {
          history.push(`/sedes/detalles/${event.id}`);
        }}
      />
    </Hidden>

    {/*Mobile grid*/}
    <Hidden mdUp implementation="css">
      <DataGrid 
        rows={rows} 
        columns={mobileColumns}
        direction="column"
        pageSize={20}
        density='compact' // or compact or 
        autoHeight={true}
        className={classes.manrope}
        onRowClick={async (event) => {
          branchStore.clear();
          branchStore.getOne(event.id)
          history.push('/sedes/detalles');
        }}
      />
    </Hidden>
    </div>
  );
});

export default branchTable;