import { observer } from 'mobx-react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import DrawerContent from './DrawerContent'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 230, // drawer width
    backgroundColor: '#fdf6e3',
    paddingTop: 60, // app bar height
  },
}));

const Drawer = observer(() => {
  const classes = useStyles();
  return (
    <Hidden mdDown implementation="css">
    <MuiDrawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <DrawerContent/>
    </MuiDrawer>
    </Hidden>
  )
});
export default Drawer;