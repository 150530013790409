import React from 'react';
import { AppBar as MuiAppBar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

// import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
const appBarHeight = 60;
const useStyles = makeStyles((theme) => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    zIndex: theme.zIndex.drawer + 1,
    height: appBarHeight,
    backgroundColor: '#268bd2'
  },
}));

const Switch = () => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const { logout } = useAuth0();
  // const { user, isAuthenticated, isLoading } = useAuth0();

  // to be removed?
  const profileButton = (
    <div>
      <Avatar 
        // alt={`${user?.name}`}
        // src={user?.picture}
        // onClick={handleOpenMenu}
      />
      {/*{menu}*/}
    </div>
  );

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <IconButton
        color="inherit"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>

      <div className={classes.titleContainer}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          Tu Examen Medico
        </Typography>
      </div>

      <div>
        {profileButton}
      </div>
    </MuiAppBar>
  )
}
export default Switch;