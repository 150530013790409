import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let apiUrl = 'https://api.tuexamenmedico.com/services'; // this should point to admin API eventually

export default class contentGeneratorStore {

	backgroundColor = 'rgb(250, 250, 203)';
	textColor = 'rgb(100, 100, 100)';
	linkColor = '';
	exam = '';
	examOptions = [];
	logo = '';

	constructor() {
		makeAutoObservable(this);
		this.getDistinctExams(); //not sure about this
		this.examOptions = ['hola', 'como']
	}

	/* 
		API related functions
	*/
	async getDistinctExams() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/distinct?field=exams.name');
		const data = await response.json();
		runInAction(() => {
			this.examOptions = data.distinctValues;
		});
		this.loading = false;
	}

	/* 
		Setters

		TODO: find a better way to do this, it's too long

	*/
	setBackgroundColor(backgroundColor) {
		this.backgroundColor = backgroundColor;
	}

	setTextColor(textColor) {
		this.textColor = textColor;
	}

	setLinkColor(linkColor) {
		this.linkColor = linkColor;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const ContentGeneratorStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the branch store in any functional component */
export const useContentGeneratorStore = () => React.useContext(StoreContext);