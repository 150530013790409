import React from "react"
import { observer } from 'mobx-react'
import ServiceTable from '../../components/ServiceTable';
import Grid from '@material-ui/core/Grid';

const InstitutionListPage = observer(() => {
	return (
		<Grid 
			container 
			spacing={3}
			direction="row"
			alignItems="center"
			justifyContent="center"
  	>
  		<Grid item xs={11}/>
			<Grid item xs={11}/>

			{/* Table */}
			<Grid item xs={11}>
				<ServiceTable />
			</Grid>
		</Grid>
	)
})

export default InstitutionListPage;