import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField'
import { useStore } from "../../stores/serviceStore";
import { useOptionsStore } from '../../stores/optionsStore';
import { useHistory } from 'react-router-dom';
import Select from '../../components/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Options from '../../utils/Options';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
    margin: 40,
  },
  tall: {
    height: '80vh',
  }
}));

let optionsStore = {};

const CreateServicePage = observer(() => {
	const serviceStore = useStore();
	const classes = useStyles();
  optionsStore = useOptionsStore();
  const history = useHistory();

	return (
    <Grid 
      container 
      direction="row"
      alignItems="flex-start"
      justify="center"
      className={classes.tall}
    >
    <Grid container item spacing={2} xs={11}>
      <Grid item xs={12}>
  			<Typography variant="h4" gutterBottom className={classes.title}>
          Registra un nuevo Servicio
        </Typography>
      </Grid>
        <Grid item xs={12}>
          <TextField
            value={serviceStore.service.branch.name}
            label='Sede'
            disabled={true}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Select
            name={'name'} 
            options={optionsStore.options.service.name}
            defaultValue={serviceStore.service.name}
            action={(name) => {serviceStore.setName(name)}}
            label='Nombre'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={serviceStore.service.address.address}
            onChange={(address) => {serviceStore.setAddress(address)}}
            label='Dirección'
            placeholder='...'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'neighbourhood'} 
            options={optionsStore.options.address.neighbourhood}
            defaultValue={serviceStore.service.address.neighbourhood}
            action={(neighbourhood) => {serviceStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'city'} 
            options={optionsStore.options.address.city}
            defaultValue={serviceStore.service.address.city}
            action={(city) => {serviceStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'state'} 
            options={optionsStore.options.address.state}
            defaultValue={serviceStore.service.address.state}
            action={(state) => {serviceStore.setState(state)}}
            label='Estado'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'municipality'} 
            options={optionsStore.options.address.municipality}
            defaultValue={serviceStore.service.address.municipality}
            action={(municipality) => {serviceStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={2}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={
              !serviceStore.service.name || 
              !serviceStore.service.address.neighbourhood ||
              !serviceStore.service.address.municipality ||
              !serviceStore.service.address.city ||
              !serviceStore.service.address.state
            }
            onClick={async () => { 
              const response = await serviceStore.insertOne();
              history.push(`/servicios/detalles/${response.insertedId}`);
            }}
          >
            Crear Servicio
          </Button>
        </Grid>
        <Grid item xs={5}/>
        </Grid>
      </Grid>
	);

});

export default CreateServicePage;