import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/clientStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '../../components/Select';
import { Paper } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import TextArea from '../../components/TextArea';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
    paper: {
      padding: 10,
      backgroundColor: '#f1f3f3'
    },
    deleteButton: {
      backgroundColor: '#dc322f',
      color: 'white',
      '&:hover': {
        backgroundColor: '#b02825',
      },
    },
    bold: {
      fontWeight: 'bold',
    }
}));
  
let clientStore = {};
let optionsStore = {};

const AdminInformationForm = observer(() => {
  const classes = useStyles();
  clientStore = useStore();
  optionsStore = useOptionsStore();
  const history = useHistory();

  const [deleteDialogIsOpen, setDeleteDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDeleteDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteClient = async () => {
    await clientStore.deleteOne(clientStore.client.id);
    setDeleteDialogOpen(false);
    history.push('/clientes/buscar');
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion Administrativa
          </Typography>
        </Grid>

        {/* Estatus */}
        <Grid item xs={12}>
          <Select
            name={'status'} 
            options={optionsStore.options.status}
            defaultValue={clientStore.client.status}
            action={(status) => {clientStore.setStatus(status)}}
            label='Estatus'
          />
        </Grid>

        {/* Notas administrativas */}
        <Grid item xs={12}>
          <TextArea
            label='Observaciones Administrativas'
            value={clientStore.client.notes.admin}
            action={(notes) => {clientStore.setAdminNotes(notes)}}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Herramientas Administrativas
          </Typography>
        </Grid>

        {/* Eliminar Cliente */}
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Eliminar Cliente
                </Typography>
              </Grid>
              <Grid item xs={12}>
                Esta acción eliminará el cliente y todos sus registros asociados (sedes y servicios) de forma inmediata y permanentemente.
              </Grid>
              <Grid item xs={12}>
                <div className={classes.bold}>
                  ¿Está ABSOLUTAMENTE SEGURO de que desea eliminar este cliente?
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.deleteButton}
                  variant='contained'
                  onClick={handleOpenDialog}
                >
                  Eliminar Cliente
                </Button>        
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Dialog */}
        <Dialog
          open={deleteDialogIsOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"¿Está seguro de que desea eliminar este cliente?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Una vez que elimine este cliente, no habra forma de recuperarlo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClient} color="primary" autoFocus>
              Eliminar Cliente
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
})

export default AdminInformationForm;