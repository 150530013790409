import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let apiUrl = 'https://adminapi.tuexamenmedico.com/admin'; // this should point to admin API eventually

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/admin';
} else {
	// apiUrl = 'http://localhost:3000';
}

export default class optionsStore {
	id = '';
	saved = true;
	loading = false;
	success = false;
	successMessage = '';

	options = {
		address: {},
		client:{},
		service: {},
		status: false,
		exams: {},
	};

	constructor() {
		makeAutoObservable(this);
		this.getOptions(); //not sure about this
	}

	async clear() {
		runInAction(() => {
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		API related functions
	*/

	async getOptions() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/options');
		const optionsDoc = await response.json();
		runInAction(() => {
			this.options = optionsDoc;
		});

		this.loading = false;
	}

	async saveOptions() {

  	//FIXME refactor into async calls
  	this.loading = true;

		await fetch(apiUrl + '/options', {
		  method: 'PATCH',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.options),
		});

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente';
	}


	/* 
		Setters

		TODO: find a better way to do this, it's too long

	*/
	setState(state) {
		this.options.address.state = state;
	}

	setCity(city) {
		this.options.address.city = city;
	}

	setMunicipality(municipality) {
		this.options.address.municipality = municipality;
	}

	setNeighbourhood(neighbourhood) {
		this.options.address.neighbourhood = neighbourhood;
	}

	setSpecialServices(specialServices) {
		this.options.service.specialServices = specialServices;
	}

	setAppointments(appointments) {
		this.options.service.appointments = appointments;
	}

	setResultTypes(resultTypes) {
		this.options.service.resultTypes = resultTypes;
	}

	setPaymentTypes(paymentTypes) {
		this.options.service.paymentTypes = paymentTypes;
	}

	setServiceNames(names) {
		this.options.service.name = names;
	}

	setInstitutionTypes(institutionTypes) {
		this.options.client.institutionType = institutionTypes;
	}

	setExams(exams) {
		this.options.service.exams = exams;
	}

	setInsuranceCompanies(insuranceCompanies) {
		this.options.service.insuranceCompanies = insuranceCompanies;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const OptionsStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the branch store in any functional component */
export const useOptionsStore = () => React.useContext(StoreContext);