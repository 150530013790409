import React from 'react';
import { observer } from 'mobx-react'
import { useStore } from '../../stores/branchStore';
import { useOptionsStore } from '../../stores/optionsStore';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField'
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Select from '../../components/Select';
import Options from '../../utils/Options';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
    margin: 40,
  },
  tall: {
    height: '80vh',
  }
}));

let optionsStore = {};

const branchDetailPage = observer(() => {

  const branchStore = useStore();
  optionsStore = useOptionsStore();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid 
      container 
      direction="row"
      alignItems="flex-start"
      justify="center"
      className={classes.tall}
    >
    <Grid container item spacing={2} xs={11}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          Registra una nueva sede
        </Typography>
      </Grid>
        <Grid item xs={12}>
          <TextField
            value={branchStore.branch.client.tradeName}
            label='Cliente'
            disabled={true}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={branchStore.branch.name}
            onChange={(name) => {branchStore.setName(name)}}
            label='Nombre'
            placeholder='...'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={branchStore.branch.address.address}
            onChange={(address) => {branchStore.setAddress(address)}}
            label='Dirección'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'neighbourhood'} 
            options={optionsStore.options.address.neighbourhood}
            defaultValue={branchStore.branch.address.neighbourhood}
            action={(neighbourhood) => {branchStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'state'} 
            options={optionsStore.options.address.city}
            defaultValue={branchStore.branch.address.city}
            action={(city) => {branchStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'state'} 
            options={optionsStore.options.address.state}
            defaultValue={branchStore.branch.address.state}
            action={(state) => {branchStore.setState(state)}}
            label='Estado'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'municipality'} 
            options={optionsStore.options.address.municipality}
            defaultValue={branchStore.branch.address.municipality}
            action={(municipality) => {branchStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={2}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={
              !branchStore.branch.name || 
              !branchStore.branch.address.neighbourhood ||
              !branchStore.branch.address.municipality ||
              !branchStore.branch.address.city ||
              !branchStore.branch.address.state
            }
            onClick={async () => { 
              const response = await branchStore.insertOne();
              history.push(`/sedes/detalles/${response.insertedId}`);
            }}
          >
            Registrar sede
          </Button>
        </Grid>
        <Grid item xs={5}/>
        </Grid>
      </Grid>
  );

});

export default branchDetailPage