import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginPage = observer(() => {
  const { 
    // user, 
    isAuthenticated, 
    isLoading,
    // logout, 
    loginWithRedirect, 
  } = useAuth0();

	return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        {isLoading ? <CircularProgress color='secondary' /> : <Button variant='contained' color='secondary' onClick={() => loginWithRedirect()}>Inicia sesión</Button>}
      </Grid> 
    </Grid> 
	);

});

export default LoginPage