import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Chip from '@material-ui/core/Chip';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import GeneralInformationForm from '../../forms/branches/GeneralInformation';
import ContactInformationForm from '../../forms/branches/ContactDetails';
import ServicesForm from '../../forms/branches/Services';
import AdminInformationForm from '../../forms/branches/AdminInformationForm';
import { useStore as useBranchStore } from "../../stores/branchStore";
import { useStore as useServiceStore } from "../../stores/serviceStore";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

//FIXME to be removed eventually
const useStyles = makeStyles((theme) => ({
  root: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	backgroundColor: 'pink'
  },
  button: {
  	margin: '50px',
  	background: '#268bd2'
  },
  title: {
  	fontFamily: 'Manrope',
  	paddingTop: 20
  },
  tabs: {
  	backgroundColor: '#268bd2',
  },
  mainContainer: {
  	width: '90%',
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: '10px',
    },
  },
	deleteButton: {
		backgroundColor: '#dc322f',
		'&:hover': {
      backgroundColor: "#c62d2a",
    }
	},
	appBarContainer: {
		paddingBottom: '20px',
	}
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BranchDetailPage = observer(() => {
	const branchStore = useBranchStore();
	const serviceStore = useServiceStore();
	const params = useParams();
	const classes = useStyles();

  useEffect(() => {
  	branchStore.clear();
    branchStore.getOne(params.id);
  }, []);

	const [selectedTab, setSelectedTab] = React.useState(0);
	const [deleteDialogIsOpen, setDeleteDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDeleteDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleCreateService = async () => {
  	await serviceStore.clear();
  	await serviceStore.setBranch({
  		_id: branchStore.branch._id,
  		name: branchStore.branch.name,
  	});
  	history.push('/servicios/registrar');
  }

  const handleDeleteBranch = async () => {
  	await branchStore.deleteOne(branchStore.branch.id);
  	setDeleteDialogOpen(false);
  	history.push('/');
  }

  const handleGetNext = async () => {
		await branchStore.getNext(branchStore.branch.code);
  }
  const handleGetPrevious = async () => {
  	await branchStore.getPrevious(branchStore.branch.code);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    branchStore.setSuccess(false, '');
  };

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const history = useHistory();
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (!branchStore.branch) {
  	branchStore.branch = {};
  }

	return (
		<Grid 
			container 
			spacing={2}
			direction="column"
			alignItems="center"
			justifyContents="center"
			justify="center"
  	>
	  	<Grid 
	  		container 
	  		direction="column"
				alignItems="center"
				justify="center"
	  	>
	  		{/* Title */}
	  		<Grid container alignItems='baseline' justify='space-between' spacing={2} wrap='nowrap' xs={11}>
	  			<Grid item>
						<IconButton onClick={handleGetPrevious}>
						  <NavigateBeforeIcon />
						</IconButton>
	  			</Grid>
					<Grid item>
						<Typography variant="h5" gutterBottom className={classes.title}>
							{branchStore.branch.name}
						</Typography>
						<Typography variant="h7" gutterBottom className={classes.title}>
							Sede de {branchStore.branch.client.tradeName}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton onClick={handleGetNext}>
						  <NavigateNextIcon />
						</IconButton>
					</Grid>
				</Grid>

			{/* Chip */}
				<Grid item>
					<Chip 
						label={`SD-${branchStore.branch.code}`}
						size='small'
						color='secondary'
						clickable
						variant='outlined'
						onClick={handleOpenMenu}
					/>
		      <Menu
		        id="simple-menu"
		        anchorEl={anchorEl}
		        keepMounted
		        open={Boolean(anchorEl)}
		        onClose={handleCloseMenu}
		      >
		        <MenuItem onClick={handleCreateService}>
							<ListItemIcon>
            		<AddIcon fontSize="small" />
          		</ListItemIcon>
		        	Registrar Servicio
		        </MenuItem>
		        <MenuItem onClick={handleOpenDialog}>
							<ListItemIcon>
            		<DeleteIcon fontSize="small" />
          		</ListItemIcon>
		        	Borrar Sede
		        </MenuItem>
		      </Menu>
				</Grid>

			</Grid>
	      
			{/* Tabs */}
			<Grid item xs={11}>
				<div className={classes.appBarContainer}>
				  <AppBar position="static" className={classes.tabs}>
				    <Tabs 
				      value={selectedTab} 
				      onChange={handleTabChange} 
				      indicatorColor="primary"
				      variant="scrollable"
				    >
				      <Tab label="Informacion General" />
				      <Tab label="Datos de Contacto" />
				      <Tab label="Servicios" />
				      <Tab label="Admin" />
				    </Tabs>
				  </AppBar>
				</div>

			  {selectedTab === 0 && <GeneralInformationForm/>}
			  {selectedTab === 1 && <ContactInformationForm/>}
			  {selectedTab === 2 && <ServicesForm/>}
			  {selectedTab === 3 && <AdminInformationForm/>}
			</Grid>

			{/* Save button */}
			<Grid item xs={10}>
				<Button
					className={classes.button}
					variant="contained" 
					color="primary"
					onClick={() => { branchStore.saveBranch() }}
				>
					Guardar
				</Button>
			</Grid>
				<div className={classes.snack}>
					<Snackbar open={branchStore.success} autoHideDuration={4000} onClose={handleClose}>
	        	<Alert onClose={handleClose} variant="filled" severity="success">
		        	{branchStore.successMessage}
		        </Alert>
	      	</Snackbar>
      	</div>
      	<div>
					<Dialog
		        open={deleteDialogIsOpen}
		        onClose={handleCloseDialog}
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">{"Quieres borrar esta sede?"}</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            Una vez que borres esta sede, no habra forma de recuperarla
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={handleCloseDialog} color="primary">
		            Cancelar
		          </Button>
		          <Button onClick={handleDeleteBranch} color="primary" autoFocus>
		            Borrar Sede
		          </Button>
		        </DialogActions>
		      </Dialog>
      	</div>
		</Grid>
	);

});

export default BranchDetailPage