import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/clientStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import Grid from '@material-ui/core/Grid';
import SelectCreatable from '../../components/SelectCreatable';

import { useOptionsStore } from '../../stores/optionsStore';
import Select from '../../components/Select';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
let clientStore = {};
let optionsStore = {};
let stageOptions = [
  'Aliado/Nuevo',
  'Prospecto',
  'Cliente',
  'Cliente fiel',
];

const SalesInformationForm = observer(() => {
  const classes = useStyles();
  clientStore = useStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion de Ventas
          </Typography>
        </Grid>

        {/* Estatus */}
        <Grid item xs={12}>
          <Select
            name={'stage'} 
            options={stageOptions}
            defaultValue={clientStore.client.stage}
            action={(stage) => {clientStore.setStage(stage)}}
            label='Etapa de venta'
          />
        </Grid>

        {/* Persona Contacto */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Persona contacto
          </Typography>
        </Grid>

        {/* Nombre */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contactPerson.name}
            onChange={(name) => {clientStore.setContactPersonName(name)}}
            label='Nombre'
          />
        </Grid>

        {/* Telefono */}
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={clientStore.client.contactPerson.phoneNumbers} 
            action={(phones) => {clientStore.setContactPersonPhones(phones)}}
            label='Telefonos'
          />
        </Grid>

        {/* Whatsapp */}
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={clientStore.client.contactPerson.whatsapp} 
            action={(whatsapp) => {clientStore.setContactPersonWhatsapp(whatsapp)}}
            label='Whatsapp'
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contactPerson.email}
            onChange={(email) => {clientStore.setContactPersonEmail(email)}}
            label='Email'
          />
        </Grid>

        {/* Cargo */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contactPerson.rank}
            onChange={(rank) => {clientStore.setContactPersonRank(rank)}}
            label='Cargo'
          />
        </Grid>

      </Grid>
    </div>
  )
})

export default SalesInformationForm;