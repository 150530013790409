import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react';
import { useStore as useServiceStore } from '../../stores/serviceStore';
import { useStore as useBranchStore } from '../../stores/branchStore';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
const ServicesForm = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  let branchStore = useBranchStore();
  let serviceStore = useServiceStore();

  const handleCreateService = async () => {
    await serviceStore.clear();
    await serviceStore.setBranch({
      _id: branchStore.branch._id,
      name: branchStore.branch.name,
    });
    await serviceStore.setAddress(branchStore.branch.address.address);
    await serviceStore.setNeighbourhood(branchStore.branch.address.neighbourhood);
    await serviceStore.setCity(branchStore.branch.address.city);
    await serviceStore.setState(branchStore.branch.address.state);
    await serviceStore.setMunicipality(branchStore.branch.address.municipality);
    await serviceStore.setPhones(branchStore.branch.contact.phones);
    await serviceStore.setCellPhones(branchStore.branch.contact.cellPhones);
    await serviceStore.setWhatsapp(branchStore.branch.contact.whatsapp);
    await serviceStore.setEmail(branchStore.branch.contact.email);
    await serviceStore.setStatus('Por revisar');
    history.push('/servicios/registrar');
  }

  let columns = [
    { field: 'name', headerName: 'Nombre', flex: 1, headerClassName: 'super-app-theme--header' },
  ];
  let rows = [];

  for (const service of branchStore.branch.services) {
    rows.push({
      id: service._id,
      name: service.name,
    });
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleCreateService}
          >
            <AddIcon fontSize="small" />
              Registrar servicio
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataGrid 
            rows={rows}
            columns={columns}
            direction="column"
            pageSize={20}
            density='standard' // or 'comfortable'
            autoHeight={true}
            className={classes.manrope}
            onRowClick={async (event) => {
              history.push(`/servicios/detalles/${event.id}`);
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
})

export default ServicesForm;