import React from 'react';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%',
  },
  labelContainer: { // find better values
    maxWidth: 170, 
    flexGrow: 1,
  },
  textFieldContainer: {
    minWidth: 250,
    // maxWidth: 500,
    flexGrow: 2,
  },
  label: {
    fontFamily: 'Manrope',
    padding: 25,
  },
  textArea: {
    width: '100%',
  }
}));

const TXMTextArea = observer(({label, value, action, disabled=false}) => {
  
  const classes = useStyles();

  if (!value) value = '';

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <Typography className={classes.label}>
          {label}
        </Typography>
      </div>
      <div className={classes.textFieldContainer}>
        <TextField 
          value={value}
          onChange={(event) => action(event.target.value)}
          multiline
          rows={4}
          fullwidth
          variant="outlined"
          className={classes.textArea}
          disabled={disabled}
        />
      </div>
    </div>
  );
});

export default TXMTextArea;