import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField'
import { useStore } from "../../stores/adminStore";
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Canvas from '../../components/Canvas';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
    padding: 20,
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
  },
  tall: {
    // height: '80vh',
    padding: 30
  },
  paper: {
    margin: 25,
    textAlign: 'center',
  },
  padding: {
    padding: 20
  }
}));

const contentGeneratorPage = observer(() => {

  const adminStore = useStore();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid 
      container 
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      className={classes.tall}
    >
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12}>
        <Canvas/>
      </Grid>
    </Grid>
  );

});

export default contentGeneratorPage;