import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/serviceStore';
import { useOptionsStore } from '../../stores/optionsStore';
import SelectMulti from '../../components/SelectMulti';
import SelectCreatable from '../../components/SelectCreatable';
import TimePicker from '../../components/TimePicker';
import TimePickerSocial from '../../components/TimePickerSocial';
import TextArea from '../../components/TextArea';
import TextField from '../../components/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    title: {
      fontFamily: 'Manrope',
    },
}));

let serviceStore = {};
let optionsStore = {};

const ServicesForm = observer(() => {

  const classes = useStyles();
  serviceStore = useStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Atencion y Servicios
          </Typography>
        </Grid>
        {/* METODO DE ATENCION */}
        <Grid item xs={12}>
          <SelectMulti 
            name={'appointments'} 
            options={optionsStore.options.service.appointments} 
            defaultValue={serviceStore.service.appointments} 
            action={(appointments) => {serviceStore.setAppointments(appointments)}}
            label='Metodo de atencion'
          />
        </Grid>

        <Grid item xs={12}>
        {/* OBSERVACIONES */}
            <TextArea
              label='Observaciones'
              value={serviceStore.service.notes.appointments}
              action={(notes) => {serviceStore.setAppointmentsNotes(notes)}}
            />
        </Grid>

        {/* HORARIO DE ATENCION */}
        <Grid item xs={12}>
          <TimePickerSocial
            label='Horario de atencion'
            schedule={serviceStore.service.businessSchedule}
            setSchedule={(schedule) => {serviceStore.setBusinessSchedule(schedule)}}
          />
        </Grid>

        <Grid item xs={12}>
        {/* OBSERVACIONES */}
            <TextArea
              label='Observaciones'
              value={serviceStore.service.notes.businessSchedule}
              action={(notes) => {serviceStore.setBusinessScheduleNotes(notes)}}
            />
        </Grid>


        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Servicios Especiales
          </Typography>
        </Grid>

        {/* SERVICIOS ESPECIALES */}
        <Grid item xs={12}>
          <SelectMulti 
            name={'specialServices'} 
            options={optionsStore.options.service.specialServices} 
            defaultValue={serviceStore.service.specialServices} 
            action={(specialServices) => {serviceStore.setSpecialServices(specialServices)}}
            label='Servicios Especiales'
          />
        </Grid>


        <Grid item xs={12}>
        {/* OBSERVACIONES */}
            <TextArea
              label='Observaciones'
              value={serviceStore.service.notes.specialServices}
              action={(notes) => {serviceStore.setSpecialServicesNotes(notes)}}
            />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Entrega de Resultados
          </Typography>
        </Grid>

        {/* ENTREGA DE RESULTADOS */}
        <Grid item xs={12}>
          <SelectMulti 
            name={'resultTypes'} 
            options={optionsStore.options.service.resultTypes} 
            defaultValue={serviceStore.service.resultTypes} 
            action={(resultTypes) => {serviceStore.setResultTypes(resultTypes)}}
            label='Metodos de entrega'
          />
        </Grid>

        {/* HORARIOS DE ENTREGA DE RESULTADOS */}
        <Grid item xs={12}>
          <TimePicker 
            label='Horario de entrega de resultados'
            schedule={serviceStore.service.resultsSchedule}
            setSchedule={(schedule) => {serviceStore.setResultsSchedule(schedule)}}
          />
        </Grid>

        {/* OBSERVACIONES */}
        <Grid item xs={12}>
            <TextArea
              label='Observaciones'
              value={serviceStore.service.notes.resultsSchedule}
              action={(notes) => {serviceStore.setResultsScheduleNotes(notes)}}
            />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Formas de Pago
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {/* FORMAS DE PAGO */}
          <SelectMulti 
            name={'payment-types'} 
            options={optionsStore.options.service.paymentTypes} 
            defaultValue={serviceStore.service.paymentTypes} 
            action={(paymentTypes) => {serviceStore.setPaymentTypes(paymentTypes)}}
            label='Formas de pago'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Solicitud de presupuesto
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* EMAIL */} 
          <TextField
            value={serviceStore.service.budgetRequest.email}
            onChange={(email) => {serviceStore.setBudgetRequestEmail(email)}}
            label='Email'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* DEPARTAMENTO */} 
          <TextField
            value={serviceStore.service.budgetRequest.department}
            onChange={(department) => {serviceStore.setBudgetRequestDepartment(department)}}
            label='Departamento'
          />
        </Grid>

        {/* TELEFONOS */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={serviceStore.service.budgetRequest.phones} 
            action={(phones) => {serviceStore.setBudgetRequestPhones(phones)}}
            label='Telefonos'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* CONTACTO */} 
          <TextField
            value={serviceStore.service.budgetRequest.contact}
            onChange={(contact) => {serviceStore.setBudgetRequestContact(contact)}}
            label='Contacto'
          />
        </Grid>


      </Grid>
    </div>
  )
})

export default ServicesForm;