import React from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import DrawerContent from './DrawerContent'

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 230, // drawer width
    backgroundColor: '#fdf6e3',
    paddingTop: 60, // app bar height
  },
}));

const MobileDrawer = observer(() => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen) };
  return (
    <Hidden lgUp implementation="css">
    <Drawer
      variant='temporary'
      anchor='left'
      open={mobileOpen}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <DrawerContent/>
    </Drawer>
    </Hidden>
  )
});
export default MobileDrawer;