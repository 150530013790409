import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/serviceStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '../../components/Select';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextArea from '../../components/TextArea';
import { useOptionsStore } from '../../stores/optionsStore';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
    paper: {
      padding: 10,
      backgroundColor: '#f1f3f3'
    },
    copyButton: {
      backgroundColor: '#2aa198',
      color: 'white',
      '&:hover': {
        backgroundColor: '#259088',
      },
    },
    deleteButton: {
      backgroundColor: '#dc322f',
      color: 'white',
      '&:hover': {
        backgroundColor: '#b02825',
      },
    },
    unpublishButton: {
      backgroundColor: '#cb4b16',
      color: 'white',
      '&:hover': {
        backgroundColor: '#b02825',
      },
    },
    publishButton: {
      backgroundColor: '#859900',
      color: 'white',
      '&:hover': {
        backgroundColor: '#859900',
      },
    },
    unPublishButton: {
      backgroundColor: '#800020',
      color: 'white',
      '&:hover': {
        backgroundColor: '#800020',
      },
    },
    bold: {
      fontWeight: 'bold',
    }
}));
  
let serviceStore = {};
let optionsStore = {};

const AdminInformationForm = observer(() => {
  const classes = useStyles();
  serviceStore = useStore();
  optionsStore = useOptionsStore();
  const history = useHistory();

  const [deleteDialogIsOpen, setDeleteDialogOpen] = React.useState(false);
  const [unpublishDialogIsOpen, setUnpublishDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDeleteDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenUnpublishDialog = () => {
    setUnpublishDialogOpen(true);
  };
  const handleCloseUnpublishDialog = () => {
    setUnpublishDialogOpen(false);
  };

  const handleDeleteService = async () => {
    await serviceStore.deleteOne(serviceStore.service.id);
    setDeleteDialogOpen(false);
    history.push('/servicios/buscar');
  }

  const handleUnpublishService = async () => {
    console.log('borrando')
    await serviceStore.unPublishOne(serviceStore.service._id);
    setUnpublishDialogOpen(false);
    // history.push('/servicios/buscar');
  }

  const lastPublishedAt = new Date(serviceStore.service.lastPublishedAt);
  const lastPublishedAtStr = `${lastPublishedAt.getDate()}/${lastPublishedAt.getMonth() + 1}/${lastPublishedAt.getFullYear()} - ${lastPublishedAt.getHours()}:${lastPublishedAt.getMinutes()}:${lastPublishedAt.getSeconds()}`;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Estatus
          </Typography>
        </Grid>

        {/* Estatus */}
        <Grid item xs={12}>
          <Select
            name={'status'} 
            options={optionsStore.options.status}
            defaultValue={serviceStore.service.status}
            action={(status) => {serviceStore.setStatus(status)}}
            label='Estatus'
          />
        </Grid>

      {/* Notas administrativas */}
        <Grid item xs={12}>
          <TextArea
            label='Observaciones Administrativas'
            value={serviceStore.service.notes.admin}
            action={(notes) => {serviceStore.setAdminNotes(notes)}}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Herramientas Administrativas
          </Typography>
        </Grid>

        {/* Duplicar Servicio */}
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Duplicar Servicio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                Registra un nuevo servicio usando los mismos datos de este (incluyendo su sede)
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.copyButton}
                  variant='contained'
                  onClick={ async() => {
                    const response = await serviceStore.insertOne(); // this works
                    history.push(`/servicios/detalles/${response.insertedId}`); // this navigates but does not refresh data :(
                    history.go();
                  }}
                >
                  Duplicar Servicio
                </Button>        
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Borrar Servicio */}
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Eliminar Servicio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                Esta acción eliminará el servicio de forma inmediata y permanentemente.
              </Grid>
              <Grid item xs={12}>
                <div className={classes.bold}>
                  ¿Está ABSOLUTAMENTE SEGURO de que desea eliminar este servicio?
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.deleteButton}
                  variant='contained'
                  onClick={handleOpenDialog}
                >
                  Eliminar Servicio
                </Button>        
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Publicar Servicio */}
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Publicar Servicio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {serviceStore.service.published ? <div className={classes.bold}>Este servicio está publicado actualmente</div> : <div className={classes.bold}>Este servicio no está publicado actualmente</div>}
              </Grid>
              <Grid item xs={12}>
                <small>{serviceStore.service.published ? `Última publicación: ${lastPublishedAtStr}` : <div></div>}</small>
              </Grid>
              <Grid item xs={12}>
                Publica este servicio en tuexamenmedico.com
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.publishButton}
                  variant='contained'
                  onClick={ async() => {
                    const response = await serviceStore.publishOne(serviceStore.service._id);
                  }}
                >
                  Publicar Servicio
                </Button>        
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Despublicar Servicio */}
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Despublicar Servicio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                Esta acción despublicará el servicio de forma inmediata y no será visible en  www.tuexamenmedico.com.
              </Grid>
              <Grid item xs={12}>
                <div className={classes.bold}>
                  ¿Está ABSOLUTAMENTE SEGURO de que desea despublicar este servicio?
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.deleteButton}
                  variant='contained'
                  onClick={handleOpenUnpublishDialog}
                  disabled={!serviceStore.service.published}
                >
                  Despublicar Servicio
                </Button>        
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>


        <Dialog
          open={deleteDialogIsOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"¿Está seguro de que desea eliminar este servicio?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Una vez que elimine este servicio, no habra forma de recuperarlo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteService} color="primary" autoFocus>
              Eliminar Servicio
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={unpublishDialogIsOpen}
          onClose={handleCloseUnpublishDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"¿Está seguro de que desea despublicar este servicio?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si despublicas este servicio, lo puedes volver a publicar usando las herramientas administrativas en esta página.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUnpublishDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleUnpublishService} color="primary" autoFocus>
              Despublicar Servicio
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
})

export default AdminInformationForm;