import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/branchStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import SelectCreatable from '../../components/SelectCreatable';
import Select from '../../components/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
let branchStore = {};
let optionsStore = {};

const ContactDetails = observer(() => {
  const classes = useStyles();
  branchStore = useStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Direccion Comercial
          </Typography>
        </Grid>

        {/* DIRECCION */} 
        <Grid item xs={12}>
          <TextField
            value={branchStore.branch.address.address}
            onChange={(address) => {branchStore.setAddress(address)}}
            label='Direccion'
            placeholder=''
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* SECTOR */} 
          <Select
            name={'neighbourhood'} 
            options={optionsStore.options.address.neighbourhood}
            defaultValue={branchStore.branch.address.neighbourhood}
            action={(neighbourhood) => {branchStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* CIUDAD */} 
          <Select
            name={'state'} 
            options={optionsStore.options.address.city}
            defaultValue={branchStore.branch.address.city}
            action={(city) => {branchStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* ESTADO */} 
          <Select
            name={'state'} 
            options={optionsStore.options.address.state}
            defaultValue={branchStore.branch.address.state}
            action={(state) => {branchStore.setState(state)}}
            label='Estado'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* MUNICIPIO */} 
          <Select
            name={'municipality'} 
            options={optionsStore.options.address.municipality}
            defaultValue={branchStore.branch.address.municipality}
            action={(municipality) => {branchStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Datos de Contacto
          </Typography>
        </Grid>

        {/* TELEFONOS */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={branchStore.branch.contact.phones} 
            action={(phones) => {branchStore.setPhones(phones)}}
            label='Telefonos'
          />
        </Grid>

        {/* CELULARES */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={branchStore.branch.contact.cellPhones} 
            action={(cellPhones) => {branchStore.setCellPhones(cellPhones)}}
            label='Celulares'
          />
        </Grid>

        {/* WHATSAPP */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={branchStore.branch.contact.whatsapp} 
            action={(whatsapp) => {branchStore.setWhatsapp(whatsapp)}}
            label='WhatsApp'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* CORREO */} 
          <TextField
            value={branchStore.branch.contact.email}
            onChange={(email) => {branchStore.setEmail(email)}}
            label='Correo'
            placeholder='ejemplo@gmail.com'
          />
        </Grid>
      </Grid>
    </div>
  )
})

export default ContactDetails;