import React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useStore } from '../stores/serviceStore';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'manrope',
    borderStyle: 'none',
  },
  test: {
    backgroundColor: 'pink'
  },
  root: {
    '& .super-app-theme--header': {
      fontSize: 16,
    },
  },
}));

const columns = [
  { 
    field: 'code', 
    headerName: 'Codigo', 
    flex: 1, 
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip 
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'status', headerName: 'Estatus', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'name', headerName: 'Nombre', flex: 3, headerClassName: 'super-app-theme--header' },
  { field: 'branch', headerName: 'Sede', flex: 3, headerClassName: 'super-app-theme--header' },
  { field: 'exams', headerName: 'Examenes', flex: 4, headerClassName: 'super-app-theme--header' },
];

const mobileColumns = [
  { 
    field: 'code',
    headerName: 'Codigo',
    flex: 2,
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip
        size='small'
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'name', headerName: 'Nombre', flex: 5, headerClassName: 'super-app-theme--header' },
];

const serviceTable = observer(() => {
  
  const serviceStore = useStore();
  let history = useHistory();
  const classes = useStyles();
  let rows = [];

  if (serviceStore.serviceSearch.results.length) {
    for (const serviceDoc of serviceStore.serviceSearch.results) {

      let exams = '';

      for (const exam of serviceDoc.exams) {
        exams += ` ● ${exam.name} `;
      }

      let row = {
        id: serviceDoc._id,
        code: `SR-${serviceDoc.code || 1}`,
        name: serviceDoc.name,
        branch: serviceDoc.branch.name,
        status: serviceDoc.status,
        exams: exams,
      };
      rows.push(row);
    }
  }

  useEffect(async () => {
    await serviceStore.getAll();
  }, []);

  return (
    <div className={classes.root}>

    {/*Desktop grid*/}
    <Hidden smDown implementation="css">
      <DataGrid 
        rows={rows}
        columns={columns}
        direction="column"
        pageSize={20}
        density='standard' // or 'comfortable'
        autoHeight={true}
        className={classes.manrope}
        localeText={esES.props.MuiDataGrid.localeText}
        onRowClick={async (event) => {
          history.push(`/servicios/detalles/${event.id}`)
        }}
      />
    </Hidden>

    {/*Mobile grid*/}
    <Hidden mdUp implementation="css">
      <DataGrid 
        rows={rows} 
        columns={mobileColumns}
        direction="column"
        pageSize={20}
        density='compact' // or compact or 
        autoHeight={true}
        className={classes.manrope}
        onRowClick={(event) => {
          // history.push('/servicio/detalles')
          // serviceStore.getOne(event.data.id)
        }}
      />
    </Hidden>
    </div>
  );
});

export default serviceTable;