import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react';
import { useStore as useClientStore } from '../../stores/clientStore';
import { useStore as useBranchStore } from '../../stores/branchStore';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
    wide: {
      width: '60vw'
    },
    button: {
      backgroundColor: '#859900',
      '&:hover': {
        backgroundColor: "#778900",
      },
      color: 'white',
    },
}));
  
const BranchesForm = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  let clientStore = useClientStore();
  let branchStore = useBranchStore();

  const handleCreateBranch = async () => {
    await branchStore.clear();

    // associate the branch with it's client
    await branchStore.setClient({
      _id: clientStore.client._id,
      businessName: clientStore.client.businessName,
      tradeName: clientStore.client.tradeName,
    });

    // set default values for new branch
    await branchStore.setAddress(clientStore.client.address.address);
    await branchStore.setNeighbourhood(clientStore.client.address.neighbourhood);
    await branchStore.setCity(clientStore.client.address.city);
    await branchStore.setState(clientStore.client.address.state);
    await branchStore.setMunicipality(clientStore.client.address.municipality);
    await branchStore.setPhones(clientStore.client.contact.phones);
    await branchStore.setCellPhones(clientStore.client.contact.cellPhones);
    await branchStore.setWhatsapp(clientStore.client.contact.whatsapp);
    await branchStore.setEmail(clientStore.client.contact.email);
    await branchStore.setHasParking(true);
    await branchStore.setStatus('Por revisar');

    // go to create branch page
    history.push('/sedes/registrar');
  }

  let columns = [
    { field: 'name', headerName: ' ', flex: 1, headerClassName: 'super-app-theme--header' },
  ];
  let rows = [];

  for (const branch of clientStore.client.branches) {
    rows.push({
        id: branch._id,
        name: branch.name,
      });
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleCreateBranch}
          >
            <AddIcon fontSize="small" />
            Registrar sede
          </Button>
        </Grid>
        <Grid item xs={12}>
        <div className={classes.wide}>
          <DataGrid 
            rows={rows}
            columns={columns}
            direction="column"
            pageSize={20}
            density='standard' // or 'comfortable'
            autoHeight={true}
            fullWidth
            className={classes.manrope}
            onRowClick={async (event) => {
              history.push(`/sedes/detalles/${event.id}`);
            }}
          />
          </div>
        </Grid>
      </Grid>
    </div>
  )
})

export default BranchesForm;