import React from 'react';
import { observer } from 'mobx-react';
import { useStore as useServiceStore } from '../../stores/serviceStore';
import Typography from '@material-ui/core/Typography';
import Switch from '../../components/Switch';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import Radio from '../../components/Radio';
import Grid from '@material-ui/core/Grid';
import Options from '../../utils/Options';
import Link from '../../components/Link';
import { useOptionsStore } from '../../stores/optionsStore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
// import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
    deleteButton: {
      backgroundColor: '#dc322f',
      color: 'white',
      '&:hover': {
        backgroundColor: '#b02825',
      },
    },
    uploadButton: {
      backgroundColor: '#2aa198',
      color: 'white',
      '&:hover': {
        backgroundColor: '#259088',
      },
    },
}));

const handleFileUpload = async (event) => {
  console.log(event.target.files[0])
  const base64 = await convertToBase64(event.target.files[0]);
  console.log(base64)
  serviceStore.setImage(base64)
};

const handleDeleteImage = async (event) => {
  serviceStore.setImage(null)
};

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const handleSetParking = (event) => {
  let hasParking = event.target.value === 'false' ? false : true;
  serviceStore.setHasParking(hasParking);
};

// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   bottom: 0,
//   left: 0,
//   whiteSpace: 'nowrap',
//   width: 1,
// });
  
let serviceStore = {};
let optionsStore = {};

const SalesInformationForm = observer(() => {
  const classes = useStyles();
  serviceStore = useServiceStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion de Ventas
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Tipo de publicación
          </Typography>
        </Grid>

        {/* Resultado destacado */}
        <Grid item xs={12}>
          <Switch
            label='Resultado destacado'
            value={serviceStore.service.plans?.topResult}
            onChange={(value) => {serviceStore.setTopResult(value)}}
          />
        </Grid>

        <Grid item xs={12}>
          <Switch
            label='Promoción'
            value={serviceStore.service.plans?.sale}
            onChange={(value) => {serviceStore.setSale(value)}}
          />
        </Grid>

        <Grid item xs={12}>
          <TextArea
            label='Descripción de la promoción'
            value={serviceStore.service.plans.saleNotes}
            action={(notes) => {serviceStore.setSaleNotes(notes)}}
            disabled={!serviceStore.service.plans?.sale}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label='Link de la promoción'
            value={serviceStore.service.plans.saleLink}
            onChange={(link) => {serviceStore.setSaleLink(link)}}
            disabled={!serviceStore.service.plans?.sale}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Imagen
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            className={classes.uploadButton}
            variant="contained"
            component="label"
          >
          Subir Imagen
            <input
              type="file"
              accept="image"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          <Button
            className={classes.deleteButton}
            variant='contained'
            onClick={handleDeleteImage}
          >
          Borrar Imagen
          </Button>
        </Grid>


        <Grid item xs={12}>
         {serviceStore.service.image ? <img src={`${serviceStore.service.image}`} /> : <></>}
        </Grid>

      </Grid>
    </div>
  )
})

export default SalesInformationForm;