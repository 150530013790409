import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let apiUrl = 'https://adminapi.tuexamenmedico.com/services';

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/servicees';
} else {
	// apiUrl = 'http://localhost:3002/servicees';
}

export default class serviceStore {
	id = '';
	saved = true;
	loading = false;
	success = false;
	successMessage = '';

	service = {
		name: '',
		exams: [],
		branch: {
			_id: '',
			name: '',
		},
		address: {
			city: '',
			municipality: '',
			neighbourhood: '',
			address: '',
		},
		contact: {
			phones: null,
			cellPhones: null,
			email: null,
			whatsapp: null,
			website: null,
		},
    appointments: [], // metodo de atencion
    businessSchedule: {
			monday: ['',''],
			tuesday: ['',''],
			wednesday: ['',''],
			thursday: ['',''],
			friday: ['',''],
			saturday: ['',''],
			sunday: ['',''],
			whatsapp: ['', ''],
			instagram: ['', ''],
    },
    specialServices: [],
    budgetRequest: {
      email: '',
      department: '',
      phones: [],
      contact: '',
    },
    resultsSchedule: {
			monday: ['',''],
			tuesday: ['',''],
			wednesday: ['',''],
			thursday: ['',''],
			friday: ['',''],
			saturday: ['',''],
			sunday: ['',''],
			instagram: ['',''],
    },
    paymentTypes: [],
    resultTypes: [],
    notes: {
      appointments: '',
      businessSchedule: '',
      specialServices: '',
      resultsSchedule: '',
      admin: '',
    },
    status: null,
    plans: {
    	// topResult: false,
    },
    image: '',
    insuranceCompanies: [],
	};

	// data about the search performed by service
	serviceSearch = {
		searchTerm: '',
		results: [],
		count: 0,
	};
	
	constructor() {
		makeAutoObservable(this);
	}

	async clear() {
		runInAction(() => {
			this.service = {
				name: '',
				exams: [],
				branch: {
					_id: '',
					name: '',
				},
				address: {
					city: '',
					municipality: '',
					neighbourhood: '',
					address: '',
				},
				contact: {
					phones: null,
					cellPhones: null,
					email: null,
					whatsapp: null,
					website: null,
				},
		    appointments: [], // metodo de atencion
		    businessSchedule: {
					monday: ['',''],
					tuesday: ['',''],
					wednesday: ['',''],
					thursday: ['',''],
					friday: ['',''],
					saturday: ['',''],
					sunday: ['',''],
					whatsapp: ['', ''],
					instagram: ['', ''],
		    },
		    specialServices: [],
		    budgetRequest: {
		      email: '',
		      department: '',
		      phones: [],
		      contact: '',
		    },
		    resultsSchedule: {
					monday: ['',''],
					tuesday: ['',''],
					wednesday: ['',''],
					thursday: ['',''],
					friday: ['',''],
					saturday: ['',''],
					sunday: ['',''],
					instagram: ['',''],
		    },
		    paymentTypes: [],
		    resultTypes: [],
		    notes: {
		      appointments: '',
		      businessSchedule: '',
		      specialServices: '',
		      resultsSchedule: '',
		      admin: '',
		    },
		    status: null,
		    plans: {
		    	// topResult: false,
		    },
		    image: '',
		    insuranceCompanies: [],
			};
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		API related functions
	*/

	async getAll() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/');
		const serviceDocs = await response.json();

		runInAction(() => {
			this.serviceSearch.results = serviceDocs;
			this.serviceSearch.count = serviceDocs.length;
		});

		this.loading = false;
	}

  async getOne(serviceId) {
  	this.loading = true;
		const response = await fetch(`${apiUrl}/${serviceId}`);
		const serviceDoc = await response.json();

		console.log(serviceDoc)

		runInAction(() => {
			this.id = serviceDoc._id;
			this.service = serviceDoc;

			if(!this.service.plans) {
				this.service.plans = {
					// topResult: true,
				}
			}
		});


		this.loading = false;
  }

  async getNext(code) {
  	const nextCode = code + 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const serviceDoc = await response.json();
  	runInAction(() => {
  		this.id = serviceDoc._id;
  		this.service = serviceDoc;
  	});
  }

  async getPrevious(code) {
  	const nextCode = code - 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const serviceDoc = await response.json();

  	runInAction(() => {
  		this.id = serviceDoc._id;
  		this.service = serviceDoc;
  	});
  }

	async deleteOne() {
  	this.loading = true;

		const response = await fetch(`${apiUrl}/${this.id}`, {
		  method: 'DELETE',
		});

		const r = await response.json();
		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La institucion fue borrada exitosamente'

		return r;
	}

	async saveService() {

  	//FIXME refactor into async calls
  	this.loading = true;

		await fetch(apiUrl + '/' + this.id, {
		  method: 'PATCH',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.service),
		});

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'
	}

	async insertOne() {

  	this.loading = true;

  	if (this.service._id) { // if service is being duplicated
  		delete this.service._id;
  	}

		const response = await fetch(apiUrl + '/', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.service),
		});

		const serviceId = await response.json();

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'

		return serviceId;
	}

	async publishOne(serviceId) {
		this.loading = true;

		const response = await fetch(`${apiUrl}/publish/${serviceId}`, {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json' },
		});

		await this.getOne(serviceId); // does this work?

		// this.service.published = true; //workaround, should be removed

		this.loading = false;
		this.success = true;
		this.successMessage = 'El servicio fue publicado exitosamente'
	}

	async unPublishOne(serviceId) {
		this.loading = true;

		const response = await fetch(`${apiUrl}/unpublish/${serviceId}`, {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json' },
		});

		this.service.published = false; //workaround, should be removed

		this.loading = false;
		this.success = true;
		this.successMessage = 'El servicio fue despublicado exitosamente'
	}


	/* 
		Setters
	*/
	setName(name) {
		this.service.name = name;
		this.saved = false;
	}

	setTradeName(name) {
		this.service.tradeName = name;
		this.saved = false;
	}

	setAddress(address) {
		this.service.address.address = address;
		this.saved = false;
	}

	setState(state) {
		this.service.address.state = state;
		this.saved = false;
	}

	setCity(city) {
		this.service.address.city = city;
		this.saved = false;
	}

	setMunicipality(municipality) {
		this.service.address.municipality = municipality;
		this.saved = false;
	}

	setNeighbourhood(neighbourhood) {
		this.service.address.neighbourhood = neighbourhood;
		this.saved = false;
	}

	setPhones(phones) {
		this.service.contact.phones = phones;
		this.saved = false;
	}

	setCellPhones(cellPhones) {
		this.service.contact.cellPhones = cellPhones;
		this.saved = false;
	}

	setWhatsapp(whatsapp) {
		this.service.contact.whatsapp = whatsapp;
		this.saved = false;
	}

	setEmail(email) {
		this.service.contact.email = email;
		this.saved = false;
	}

	setWebsite(website) {
		this.service.contact.website = website;
		this.saved = false;
	}

	setFacebook(facebook) {
		this.service.contact.facebook = facebook;
		this.saved = false;
	}

	setYouTube(youTube) {
		this.service.contact.youtube = youTube;
		this.saved = false;
	}

	setInstagram(instagram) {
		this.service.contact.instagram = instagram;
		this.saved = false;
	}

	setTwitter(twitter) {
		this.service.contact.twitter = twitter;
		this.saved = false;
	}

	setAppointments(appointments) {
		this.service.appointments = appointments;
		this.saved = false;
	}

	setSearchTerm(searchTerm) {
		this.serviceSearch.searchTerm = searchTerm;
	}

	setBusinessSchedule(businessSchedule) {
		this.service.businessSchedule = businessSchedule;
	}

	setHasParking(hasParking) {
		this.service.hasParking = hasParking;
		this.saved = false;
	}

	setBranch(branch) {
		this.service.branch = branch;
		this.saved = false;
	}

	setHasParkingNotes(notes) {
		this.service.notes.hasParking = notes;
		this.saved = false;
	}

	setResultsScheduleNotes(resultsScheduleNotes) {
		this.service.notes.resultsSchedule = resultsScheduleNotes;
	}

	setBusinessScheduleNotes(businessScheduleNotes) {
		this.service.notes.businessSchedule = businessScheduleNotes;
	}

	setSpecialServicesNotes(specialServicesNotes) {
		this.service.notes.specialServices = specialServicesNotes;
	}

	setAppointmentsNotes(appointmentsNotes) {
		this.service.notes.appointments = appointmentsNotes;
	}

	setResultTypes(resultTypes) {
		this.service.resultTypes = resultTypes;
	}

	setSpecialServices(specialServices) {
		this.service.specialServices = specialServices;
	}

	setResultsSchedule(schedule) {
		this.service.resultsSchedule = schedule;
	}

	setBudgetRequestEmail(email) {
		this.service.budgetRequest.email = email;
	}

	setBudgetRequestDepartment(department) {
		this.service.budgetRequest.department = department;
	}

	setBudgetRequestPhones(phones) {
		this.service.budgetRequest.phones = phones;
	}

	setBudgetRequestContact(contact) {
		this.service.budgetRequest.contact = contact;
	}

	setPaymentTypes(paymentTypes) {
		this.service.paymentTypes = paymentTypes;
	}

	setExams(exams) {
		this.service.exams = exams;
	}

	setExam(exam) {
		for(let i = 0; i < this.service.exams.length; i++) {
			if(this.service.exams[i].name == exam.name) {
				console.log('setting')
				this.service.exams.[i] = exam;
			}
		}
	}

	setSuccess(success, message) {
		this.success = success;
		this.successMessage = message;
	}

	setStatus(status) {
		this.service.status = status;
	}

	setAdminNotes(notes) {
		this.service.notes.admin = notes;
	}

	setTopResult(value) {
		this.service.plans.topResult = value;
	}

	setSale(value) {
		this.service.plans.sale = value;
	}

	setSaleNotes(notes) {
		this.service.plans.saleNotes = notes;
	}

	setSaleLink(link) {
		this.service.plans.saleLink = link;
	}

	setImage(image) {
		this.service.image = image;
	}

	setInsuranceCompanies(insuranceCompanies) {
		this.service.insuranceCompanies = insuranceCompanies;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const ServiceStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the service store in any functional component */
export const useStore = () => React.useContext(StoreContext);