import React from 'react';
import { observer } from 'mobx-react'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './styles/root.css';
import styles from './styles/Radio.module.css';
import Checkbox from '@material-ui/core/Checkbox';

const TXMCheckBox = observer(({ label, value, onChange }) => {

  return (
      <div>
        <FormControlLabel control={<Checkbox checked={value} onChange={onChange}/>} label={label} />
      </div>  
  );
});

export default TXMCheckBox;