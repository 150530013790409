import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/clientStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import Radio from '../../components/Radio';
import SelectMulti from '../../components/SelectMulti';
import Grid from '@material-ui/core/Grid';
import Options from '../../utils/Options';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
let clientStore = {};
let optionsStore = {};

const GeneralInformationForm = observer(() => {
  const classes = useStyles();
  clientStore = useStore();
  optionsStore = useOptionsStore();

  // TODO: this is behaving weird.
  const handleChange = (event) => {
    let isPrivate = event.target.value === 'false' ? false : true;
    clientStore.setIsPrivate(isPrivate);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion General
          </Typography>
        </Grid>

        {/* Nombre Comercial */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.tradeName}
            onChange={(tradeName) => {clientStore.setTradeName(tradeName)}}
            label='Nombre Comercial'
          />
        </Grid>

        {/* Nombre Fiscal */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.businessName}
            onChange={(businessName) => {clientStore.setBusinessName(businessName)}}
            label='Nombre Fiscal'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
        </Grid>

        {/* RIF */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.rif}
            onChange={(rif) => {clientStore.setRif(rif)}}
            label='RIF'
          />
        </Grid>

        {/* Tipo de institucion */}
        <Grid item xs={12}>
          <SelectMulti 
            name={'institutionType'} 
            options={optionsStore.options.client.institutionType}
            defaultValue={clientStore.client.institutionTypes} 
            action={(institutionTypes) => {clientStore.setInstitutionTypes(institutionTypes)}}
            label='Tipo de institucion'
          />
        </Grid>

        {/* privada / publica */}
        <Grid item xs={12}>
          <Radio 
            label='Tipo de administracion'
            labelFalse='Publica'
            labelTrue='Privada'
            value={clientStore.client.isPrivate} // TODO: fix this
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        {/* Cantidad de Sedes */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.numberOfBranches}
            onChange={(numberOfBranches) => {clientStore.setNumberOfBranches(numberOfBranches)}}
            label='Cantidad de sedes'
          />
        </Grid>

      </Grid>
    </div>
  )
})

export default GeneralInformationForm;