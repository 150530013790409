import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStore as useClientStore } from '../../stores/clientStore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import GroupIcon from '@material-ui/icons/Group';
import SearchIcon from '@material-ui/icons/Search';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TuneIcon from '@material-ui/icons/Tune';
import InstagramIcon from '@material-ui/icons/Instagram';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 230, // drawer width
    backgroundColor: '#fdf6e3',
    paddingTop: 60, // app bar height
  },
}));

const DrawerContent = observer(() => {
	const classes = useStyles();
  let clientStore = useClientStore();

	const [clientOpen, setClientOpen] = React.useState(false);
  const [branchOpen, setBranchOpen] = React.useState(false);
  const [serviceOpen, setServiceOpen] = React.useState(false);
  const [adminOpen, setAdminOpen] = React.useState(false);
  const handleClientOpen = () => { setClientOpen(!clientOpen) };
  const handleServiceOpen = () => { setServiceOpen(!serviceOpen) };
  const handleBranchOpen = () => { setBranchOpen(!branchOpen) };
  const handleAdminOpen = () => { setAdminOpen(!adminOpen) };

  const handleCreateClient = async () => {
    await clientStore.clear();
    // set status to 'por aprobar'
    await clientStore.setStatus('Por revisar');
    handleNavigation('/clientes/registrar');
  } 

  const history = useHistory();
  
  const handleNavigation = (path) => { 
  	history.push(path) 
  } // clear stores first?

  const boldStyle = {
    fontWeight: 'bold',
  }
	return (
    <div>
      <List>
        <ListItem button onClick={handleClientOpen}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
            <ListItemText primary="Clientes" primaryTypographyProps={{ style: boldStyle }}/>
          {clientOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={clientOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/clientes/buscar')}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Buscar clientes" disableTypography />
            </ListItem>

            <ListItem button className={classes.nested} onClick={handleCreateClient}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Registrar un cliente" disableTypography />
            </ListItem>

          </List>
        </Collapse>


        <ListItem button onClick={handleBranchOpen}>
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
            <ListItemText primary="Sedes" primaryTypographyProps={{ style: boldStyle }}/>
          {branchOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={branchOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/sedes/buscar')}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Buscar sedes" disableTypography />
            </ListItem>

          </List>
        </Collapse>

        <ListItem button onClick={handleServiceOpen}>
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
            <ListItemText primary="Servicios" primaryTypographyProps={{ style: boldStyle }}/>
          {serviceOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        
        <Collapse in={serviceOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/servicios/buscar')}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Buscar servicios" disableTypography />
            </ListItem>

          </List>
        </Collapse>

        <ListItem button onClick={handleAdminOpen}>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
            <ListItemText primary="Admin" primaryTypographyProps={{ style: boldStyle }}/>
          {serviceOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={adminOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/admin/estadisticas')}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Estadisticas" disableTypography />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/admin/opciones')}>
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary="Configuración de menús desplegables" disableTypography />
            </ListItem>

            <ListItem button className={classes.nested} onClick={() => handleNavigation('/admin/generador')}>
              <ListItemIcon>
                <InstagramIcon />
              </ListItemIcon>
              <ListItemText primary="Generador de contenido para redes sociales" disableTypography />
            </ListItem>

          </List>
        </Collapse>

      </List>
      <Divider />
    </div>
  )
});
export default DrawerContent;