import React from 'react';
import { observer } from 'mobx-react'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './styles/root.css';
import styles from './styles/Radio.module.css';

const TXMRadio = observer(({ label, labelTrue, labelFalse, value, onChange }) => {

  return (
    <div className='rootContainer'>
      <div className='labelContainer'>
        <div>
          {label}
        </div>
      </div>
      <div className={styles.radioContainer}>
        <FormControlLabel 
          label={labelTrue} 
          control={
            <Radio
              checked={value}
              onChange={onChange}
              value={true}
            />
          }
        />
        <FormControlLabel 
          label={labelFalse}
          control={
            <Radio
              checked={!value}
              onChange={onChange}
              value={false}
            />
          }
        />
      </div>
    </div>  
  );
});

export default TXMRadio;