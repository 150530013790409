import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { observer } from 'mobx-react';
import './styles/root.css';
import styles from './styles/SelectCreatable.module.css';

const TXMSelectCreatable = observer(({values, action, label}) => {

	const [inputValue, setInputValue] = React.useState();

	if (!values) {
		values = [];
	}

	let vals = [];
	for (const value of values) {
		vals.push({
			value: value,
			label: value,
		})
	}

	function handleChange(values, actionMeta) {
	    if(actionMeta.action === 'remove-value') {

	    	// format values
	    	const vals = [];
	    	for (const value of values) {
	    		vals.push(value.value);
	    	}

	    	// update store
	    	action(vals);
	    }
	}

	function handleInputChange(inputValue) {
	}

	function handleKeyDown(event) {

		if (event.key === 'Enter') {
			const vals = values;
			vals.push(event.target.value);

			// updating store
			action(vals);
			// FIXME: figure out a way to clear the input itself
		}
	}

	const customStyles = {
	  control: base => ({
	    ...base,
	    minHeight: 55,
	  })
	};


	let components = {
		DropdownIndicator: null,
		ClearIndicator: null,
	};

	const StyledCreatableSelect = (props) => (
		<CreatableSelect
			isClearable
			menuIsOpen={false}
			isMulti
			closeMenuOnSelect={false}
		    styles={customStyles}
			components={ components }
			{...props}
	    />
	);	


  return(
  	<div className='rootContainer'>
      <div className='labelContainer'>
        <div>
          {label}
        </div>
      </div>
			<div className={styles.selectContainer}>
		  	<StyledCreatableSelect
	        isClearable
	        isMulti
	        inputValue={inputValue}
	        menuIsOpen={false}
	        onChange={handleChange}
	        onInputChange={handleInputChange}
	        onKeyDown={handleKeyDown}
	        value={vals}
	        placeholder="Escribe algo y presiona Enter..."
		  	/>
	  	</div>
  	</div>
  );
});

export default TXMSelectCreatable;