import React from 'react';
import { observer } from 'mobx-react';
import { useStore as useServiceStore } from '../../stores/serviceStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import SelectMulti from '../../components/SelectMulti';
import CheckBox from '../../components/CheckBox';
import Options from '../../utils/Options';
import TextArea from '../../components/TextArea'

import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';

const ExamRow = observer(({exam}) => {
    const [open, setOpen] = React.useState(false);
    let serviceStore = useServiceStore();

    function handleSetPediatrics(exam) {
      exam.hasPediatrics = !exam.hasPediatrics;
      serviceStore.setExam(exam);
    }

    function handleSetSpecialNeeds(exam) {
      exam.hasSpecialNeeds = !exam.hasSpecialNeeds;
      serviceStore.setExam(exam);
    }

    function handleSetDelivery(exam) {
      exam.hasDelivery = !exam.hasDelivery;
      serviceStore.setExam(exam);
    }

    function handleSetSedation(exam) {
      exam.hasSedation = !exam.hasSedation;
      serviceStore.setExam(exam);
    }

    function handleSetContrast(exam) {
      exam.hasContrast = !exam.hasContrast;
      serviceStore.setExam(exam);
    }

    function handleSetOpenField(exam) {
      exam.hasOpenField = !exam.hasOpenField;
      serviceStore.setExam(exam);
    }

    function handleSetDescription(description) {
      exam.description = description;
      serviceStore.setExam(exam);
    }



    return (
      <>
      <TableHead>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              // size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>


            {exam.name}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableRow>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={6}>
              <CheckBox
                label='Pediátrico / Para niños'
                value={exam.hasPediatrics}
                onChange={() => {handleSetPediatrics(exam)}}
              />
            </Grid>
            
            <Grid item xs={6}>
              <CheckBox
                label='Persona con necesidades especiales'
                value={exam.hasSpecialNeeds}
                onChange={() => {handleSetSpecialNeeds(exam)}}
              />
            </Grid>

            <Grid item xs={6}>
              <CheckBox
                label='Con sedación'
                value={exam.hasSedation}
                onChange={() => {handleSetSedation(exam)}}
              />
            </Grid>

            <Grid item xs={6}>
              <CheckBox
                label='Con contraste o doble contraste'
                value={exam.hasContrast}
                onChange={() => {handleSetContrast(exam)}}
              />
            </Grid>

            <Grid item xs={6}>
              <CheckBox
                label='A domicilio'
                value={exam.hasDelivery}
                onChange={() => {handleSetDelivery(exam)}}
              />
            </Grid>

            <Grid item xs={6}>
              <CheckBox
                label='Campo abierto'
                value={exam.hasOpenField}
                onChange={() => {handleSetOpenField(exam)}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextArea
                label='Descripción'
                value={exam.description}
                action={(description) => {handleSetDescription(description)}}
              />
            </Grid>

          </Grid>
        </Collapse>
      </TableRow>
      </>
    )
});
  
const ExamsForm = observer(() => {

  let serviceStore = useServiceStore();
  let optionsStore = useOptionsStore();

  const exams = [];

  for (const exam of serviceStore.service.exams) {
    exams.push(exam.name);
  }

  const handleSetExams = async (exams) => {
    let newExams = [];
    for (const exam of exams) {
      newExams.push({
        name: exam,
      })
    }
    serviceStore.setExams(newExams);
  }

  let rows = [];
  let rowId = 0; // hack, should be removed

  for (const exam of serviceStore.service.exams) {
    rowId++;
    rows.push({
      id: rowId,
      name: exam.name,
    });
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectMulti
            name={'exams'} 
            options={optionsStore.options.service.exams} 
            defaultValue={exams} 
            action={(exams) => {handleSetExams(exams)}}
            label='Examenes'
          />
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="purchases">
                  { serviceStore.service.exams.map(function(exam) {
                    return (
                      <ExamRow exam={exam}></ExamRow>
                    )
                  })}
              </Table>
            </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
})

export default ExamsForm;