import React from 'react';
import useRef from 'react';
import { observer } from 'mobx-react'
import { useContentGeneratorStore } from "../stores/contentGeneratorStore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const Canvas = observer(({}) => {

  const contentGeneratorStore = useContentGeneratorStore();
  const canvas = React.useRef();
  let selectedTheme = 'red';
  
  const colors = {
    red: 'rgb(232, 84, 84)',
    cream: 'rgb(250, 250, 203)',
    lightBlue: 'rgb(139, 200, 234)',
    darkBlue: 'rgb(19, 44, 94)',
    black: '',
    white: ''
  }

  const themes = {
    cream: {
      backgroundColor: colors.cream,
      fontColor: colors.red,
      linkColor: colors.darkBlue,
      logo: '/tem-rb.png',
    },
    red: {
      backgroundColor: colors.red,
      fontColor: colors.cream,
      linkColor: colors.darkBlue,
      logo: '/tuexamenmedico.png',
    },
    lightBlue: {
      backgroundColor: colors.lightBlue,
      fontColor: colors.darkBlue,
      linkColor: colors.red,
      logo: '/tuexamenmedico.png',
    },
    darkBlue: {
      backgroundColor: colors.darkBlue,
      fontColor: colors.lightBlue,
      linkColor: colors.cream,
      logo: '/tem-rw.png',
    },
  }

  let backgroundColor = '';
  let textColor = '';
  let linkColor = '';
  let exam = '';
  let examCount = '';

  React.useEffect(() => {
    draw();
  });

  const draw = () => {
    const ctx = canvas.current.getContext('2d');
    // set background color
    ctx.fillStyle = themes[selectedTheme].backgroundColor;
    ctx.fillRect(0, 0, 1080, 1080); // paint background
    
    // dynamic caption
    ctx.textAlign = "center"
    ctx.fillStyle = themes[selectedTheme].fontColor;
    ctx.font = "bold 55px Manrope"
    ctx.textBaseline = "top"
    ctx.fillText(`¿Necesitas un examen`, 540, 160);
    ctx.fillText(`de ${exam}?`, 540, 230);

    ctx.font = "35px Manrope"
    ctx.fillText("Directorio gratuito de exámenes médicos en Venezuela", 540, 500);

    ctx.textAlign = "left"
    ctx.font = "50px Manrope";
    ctx.fillText('Busca y encuentra aquí tu mejor opción', 100, 650)

    ctx.font = "70px Manrope";
    ctx.fillText('más de', 100, 805)
    ctx.fillText('centros de salud', 100, 900)

    ctx.fillStyle = themes[selectedTheme].linkColor;
    ctx.font = "bold 95px Manrope";
    ctx.fillText(examCount, 360, 785)
    // ctx.fillText(exam, 100, 930)

    // link
    ctx.textAlign = "center"
    ctx.fillStyle = themes[selectedTheme].linkColor;
    ctx.font = "bold 73px Manrope";
    ctx.fillText('www.tuexamenmedico.com', 540, 400)
    ctx.fillRect(75, 465, 935, 5);

    // logo
    let logo = new Image();
    // logo.crossOrigin= "anonymous";
    logo.src = themes[selectedTheme].logo;
    logo.onload = function () {
       ctx.drawImage(logo, 810, 800, 260, 260);
    }

    // let logo = new Image();
    // // logo.crossOrigin= "anonymous";
    // logo.src = '/temCornerBlue.png';
    // logo.onload = function () {
    //    ctx.drawImage(logo, 769, 765, 380, 380);
    // }

  }

  const handleBackgroundColor = (event) => {
    backgroundColor = event.target.value;
    draw();
  };

  const handleTheme = (event) => {
    selectedTheme = event.target.value;
    draw();
  };

  const handleTextColor = (event) => {
    textColor = event.target.value;
    draw();
  };
  const handleLinkColor = (event) => {
    linkColor = event.target.value;
    draw();
  };
  const handleExam = async (event) => {
    exam = event.target.value;
    // get exam count via API
    const response = await fetch(`https://api.tuexamenmedico.com/services/count?exams.name=${exam}`);
    const data = await response.json();
    examCount = data.count;
    draw();
  };

  return (
    <div>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Esquema de colores</InputLabel>
        <Select
          onChange={handleTheme}
        >
          <MenuItem value={'cream'} selected={true}>Crema</MenuItem>
          <MenuItem value={'red'}>Rojo</MenuItem>
          <MenuItem value={'lightBlue'}>Azul Claro</MenuItem>
          <MenuItem value={'darkBlue'}>Azul Marino</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Exámen</InputLabel>
        <Select
          onChange={handleExam}
        >
          {
            contentGeneratorStore.examOptions.map((exam) => {
              return <MenuItem value={exam}>{exam}</MenuItem>
            })
          }
        </Select>
      </FormControl>

      <Button
        variant="contained"
        onClick={async () => { 
          canvas.current.toBlob(blob => {
            let data = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = data
            link.download = 'feed.jpg'
            link.click()
          }, 'image/jpeg')
        }}
      >
        Descargar Imagen
      </Button>

      <canvas ref={canvas} width={1080} height={1080}/>
    </div>
  );
});

export default Canvas;