import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/clientStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import SelectCreatable from '../../components/SelectCreatable';
import Select from '../../components/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
let clientStore = {};
let optionsStore = {};

const ContactDetails = observer(() => {
  const classes = useStyles();
  clientStore = useStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Direccion Fiscal
          </Typography>
        </Grid>

        {/* DIRECCION */} 
        <Grid item xs={12}>
          <TextField
            value={clientStore.client.address.address}
            onChange={(address) => {clientStore.setAddress(address)}}
            label='Direccion'
            placeholder='Av. principal Las Mercedes, C.C. Shopping Mall, Local 1'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* SECTOR */} 
          <Select
            name={'neighbourhood'} 
            options={optionsStore.options.address.neighbourhood}
            defaultValue={clientStore.client.address.neighbourhood}
            action={(neighbourhood) => {clientStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* CIUDAD */}
          <Select
            name={'city'} 
            options={optionsStore.options.address.city}
            defaultValue={clientStore.client.address.city}
            action={(city) => {clientStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* ESTADO */} 
          <Select
            name={'state'} 
            options={optionsStore.options.address.state}
            defaultValue={clientStore.client.address.state}
            action={(state) => {clientStore.setState(state)}}
            label='Estado'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* MUNICIPIO */} 
          <Select
            name={'municipality'} 
            options={optionsStore.options.address.municipality}
            defaultValue={clientStore.client.address.municipality}
            action={(municipality) => {clientStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Datos de Contacto
          </Typography>
        </Grid>

        {/* TELEFONOS */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={clientStore.client.contact.phones} 
            action={(phones) => {clientStore.setPhones(phones)}}
            label='Telefonos'
          />
        </Grid>

        {/* CELULARES */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={clientStore.client.contact.cellPhones} 
            action={(cellPhones) => {clientStore.setCellPhones(cellPhones)}}
            label='Celulares'
          />
        </Grid>

        {/* WHATSAPP */} 
        <Grid item xs={12} sm={12} md={6}>
          <SelectCreatable
            values={clientStore.client.contact.whatsapp} 
            action={(whatsapp) => {clientStore.setWhatsapp(whatsapp)}}
            label='WhatsApp'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}/>

        <Grid item xs={12} sm={12} md={6}>
          {/* CORREO */} 
          <TextField
            value={clientStore.client.contact.email}
            onChange={(email) => {clientStore.setEmail(email)}}
            label='Correo'
            placeholder='ejemplo@gmail.com'
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {/* PAGNIA WEB */} 
          <TextField
            value={clientStore.client.contact.website}
            onChange={(website) => {clientStore.setWebsite(website)}}
            label='Pagina Web'
            placeholder='www.ejemplo.com'
          />
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Redes Sociales
          </Typography>
        </Grid>

        {/* INSTAGRAM */} 
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contact.instagram}
            onChange={(instagram) => {clientStore.setInstagram(instagram)}}
            label='Instagram'
            placeholder='ejemplo'
          />
        </Grid>

        {/* FACEBOOK 🔴 */} 
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contact.facebook}
            onChange={(facebook) => {clientStore.setFacebook(facebook)}}
            label='Facebook'
            placeholder='https://www.facebook.com/Grupo-de-Ejemplo-123456789'
          />
        </Grid>

        {/* TWITTER 🔴 */} 
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contact.twitter}
            onChange={(twitter) => {clientStore.setTwitter(twitter)}}
            label='Twitter'
            placeholder='ejemplo'
          />
        </Grid>

        {/* YOUTUBE 🔴 */} 
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            value={clientStore.client.contact.youtube}
            onChange={(youtube) => {clientStore.setYouTube(youtube)}}
            label='Youtube'
            placeholder='https://www.youtube.com/watch?v=ejemplo'
          />
        </Grid>
      </Grid>
    </div>
  )
})

export default ContactDetails;