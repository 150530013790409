import React from 'react';
import { observer } from 'mobx-react';
import { useStore as useServiceStore } from '../../stores/serviceStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import Radio from '../../components/Radio';
import Grid from '@material-ui/core/Grid';
import Options from '../../utils/Options';
import Link from '../../components/Link';
import SelectMulti from '../../components/SelectMulti';
import { useOptionsStore } from '../../stores/optionsStore';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));

const handleSetParking = (event) => {
  let hasParking = event.target.value === 'false' ? false : true;
  serviceStore.setHasParking(hasParking);
};
  
let serviceStore = {};
let optionsStore = {};

const GeneralInformationForm = observer(() => {
  const classes = useStyles();
  serviceStore = useServiceStore();
  optionsStore = useOptionsStore();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion General
          </Typography>
        </Grid>

        {/* Nombre */}
        <Grid item xs={12}>
          <Select
            name={'name'} 
            options={optionsStore.options.service.name}
            defaultValue={serviceStore.service.name}
            action={(name) => {serviceStore.setName(name)}}
            label='Nombre'
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Sede
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Link
            href={`/sedes/detalles/${serviceStore.service.branch._id}`}
            value={serviceStore.service.branch.name}
            label='Nombre'
          />
        </Grid>

        {/* Facilidad para estacionar */}
        <Grid item xs={12}>
          <Radio 
            label='Facilidad para estacionar'
            labelFalse='No'
            labelTrue='Si'
            value={serviceStore.service.hasParking} // TODO: fix this
            onChange={(event) => handleSetParking(event)}
          />
        </Grid>

        {/* Observaciones de estacionamiento */}
        <Grid item xs={12}>
            <TextArea
              label='Observaciones'
              value={serviceStore.service.notes.hasParking}
              action={(notes) => {serviceStore.setHasParkingNotes(notes)}}
            />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Seguros
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <SelectMulti 
            name={'insuranceCompanies'} 
            options={optionsStore.options.service.insuranceCompanies} 
            defaultValue={serviceStore.service.insuranceCompanies} 
            action={(insuranceCompanies) => {serviceStore.setInsuranceCompanies(insuranceCompanies)}}
            label='Aseguradoras'
          />
        </Grid>

      </Grid>
    </div>
  )
})

export default GeneralInformationForm;