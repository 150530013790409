import React from 'react';
import Link from '@material-ui/core/Link';
import { observer } from 'mobx-react'
// import './styles/root.css';
// import styles from './styles/TXMTextField.module.css';

const TXMLink = observer(({ href, label, value }) => {
  return (
    <div className='rootContainer'> {/* these are global classes from root.css */}
      <div className='labelContainer'> {/* they can be reused in multiple components */}
        <div> {/* leaving this div without styles so we can apply custom visual styles */}
          { label }
        </div>
      </div>
        <div>
        <Link href={href}> {value} </Link>
      </div>
    </div>
  );
});

export default TXMLink;