import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField'
import { useStore } from '../../stores/clientStore';
import { useOptionsStore } from '../../stores/optionsStore';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Select from '../../components/Select';
import Options from '../../utils/Options';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
  },
  tall: {
    height: '80vh',
  }
}));

let optionsStore = {};

const createClientPage = observer(() => {

	const clientStore = useStore();
	const classes = useStyles();
  const history = useHistory();
  optionsStore = useOptionsStore();

	return (
    <Grid 
      container 
      direction="row"
      alignItems="flex-start"
      justify="center"
      className={classes.tall}
    >
    <Grid container item spacing={2} xs={11}>
      <Grid item xs={12}>
  			<Typography variant="h4" gutterBottom className={classes.title}>
          Registra un nuevo cliente
        </Typography>
      </Grid>
        <Grid item xs={12}>
          <TextField
            value={clientStore.client.tradeName}
            onChange={(tradeName) => {clientStore.setTradeName(tradeName)}}
            label='Nombre Comercial'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'neighbourhood'} 
            options={optionsStore.options.address.neighbourhood}
            defaultValue={clientStore.client.address.neighbourhood}
            action={(neighbourhood) => {clientStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'municipality'} 
            options={optionsStore.options.address.municipality}
            defaultValue={clientStore.client.address.municipality}
            action={(municipality) => {clientStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'city'} 
            options={optionsStore.options.address.city}
            defaultValue={clientStore.client.address.city}
            action={(city) => {clientStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            name={'state'} 
            options={optionsStore.options.address.state}
            defaultValue={clientStore.client.address.state}
            action={(state) => {clientStore.setState(state)}}
            label='Estado'
          />
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={2}>
          <Button
            className={classes.button}
            variant="contained"
            disabled={
              !clientStore.client.tradeName || 
              !clientStore.client.address.neighbourhood ||
              !clientStore.client.address.municipality ||
              !clientStore.client.address.city ||
              !clientStore.client.address.state
            }
            onClick={async () => { 
              const response = await clientStore.insertOne();
              history.push(`/clientes/detalles/${response.insertedId}`);
            }}
          >
            Registrar Cliente
          </Button>
        </Grid>
        <Grid item xs={5}/>
        </Grid>
      </Grid>
	);

});

export default createClientPage