import { Switch as ReactSwitch, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CreateClientPage from './pages/clients/Create';
import ClientListPage from './pages/clients/List';
import ClientDetailPage from './pages/clients/Detail';
import CreateBranchPage from './pages/branches/Create';
import BranchListPage from './pages/branches/List'
import BranchDetailPage from './pages/branches/Detail';
import CreateServicePage from './pages/services/Create';
import ServiceListPage from './pages/services/List';
import ServiceDetailPage from './pages/services/Detail';
import AdminStatisticsPage from './pages/admin/StatisticsPage';
import ContentGeneratorPage from './pages/admin/ContentGeneratorPage';
import OptionsPage from './pages/admin/OptionsPage';
const Routes = () => {
  return (
    
    <ReactSwitch>
      <Route exact path='/' component={HomePage}></Route>


      <Route exact path='/clientes/registrar' component={CreateClientPage}></Route>
      <Route exact path='/clientes/detalles' component={ClientDetailPage}></Route>
      <Route path='/clientes/detalles/:id' component={ClientDetailPage}></Route>
      <Route path={["/clientes", "/clientes/buscar"]} component={ClientListPage}></Route>

      <Route exact path='/sedes/registrar' component={CreateBranchPage}></Route>
      <Route exact path='/sedes/detalles' component={BranchDetailPage}></Route>
      <Route path='/sedes/detalles/:id' component={BranchDetailPage}></Route>
      <Route path={["/sedes", "/sedes/buscar"]} component={BranchListPage}></Route>

      <Route exact path='/servicios/registrar' component={CreateServicePage}></Route>
      <Route exact path='/servicios/detalles' component={ServiceDetailPage}></Route>
      <Route path='/servicios/detalles/:id' component={ServiceDetailPage}></Route>
      <Route path={["/servicios", "/servicios/buscar"]} component={ServiceListPage}></Route>

      <Route exact path='/admin/estadisticas' component={AdminStatisticsPage}></Route>
      <Route exact path='/admin/opciones' component={OptionsPage}></Route>
      <Route exact path='/admin/generador' component={ContentGeneratorPage}></Route>
    </ReactSwitch>
    
  )
}
export default Routes;