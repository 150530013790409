import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useAuth0 } from "@auth0/auth0-react";


const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
  },
  clientsButton: {
    transition: "0.5s ease",
    backgroundColor: '#2aa198',
    '&:hover': {
      backgroundColor: "#2a8ca1",
    },
    color: 'white',
    width: '15vw',
    height: '30',
    fontSize: 45,
  },
  branchesButton: {
    transition: "0.5s ease",
    backgroundColor: '#cb4b16',
    '&:hover': {
      backgroundColor: "#cb1e16",
    },
    color: 'white',
    width: '15vw',
    height: '30',
    fontSize: 45,
  },
  servicesButton: {
    transition: "0.5s ease",
    backgroundColor: '#6c71c4',
    '&:hover': {
      backgroundColor: "#865abd",
    },
    color: 'white',
    width: '15vw',
    height: '30',
    fontSize: 45,
  },
}));

const LoginButton = () => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();

  if(!isAuthenticated)
    return <button onClick={() => loginWithRedirect()}>Log In</button>;
  else
    return (<div/>)
};

const HomePage = observer(() => {
	const classes = useStyles();
  const history = useHistory();

	return (
    <Grid 
      container 
      direction="column"
      alignItems="center"
      justifyContent="space-around"
      className={classes.tall}
    >
      <Grid item xs={12}>
  			<Typography variant="h2" gutterBottom className={classes.title}>
          Tu Examen Medico
        </Typography>
      </Grid>
      <Grid 
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item sm={4}>
          <Paper 
            elevation={3} 
            className={classes.clientsButton}
            onClick={() => {history.push('/clientes')}}
          >
            <Grid container item direction="row" alignItems="center" justifyContent="center">
              clientes
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper
            elevation={3} 
            className={classes.branchesButton}
            onClick={() => {history.push('/sedes')}}
          >
            <Grid container item direction="row" alignItems="center" justifyContent="center">
              sedes
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper 
            elevation={3} 
            className={classes.servicesButton}
            onClick={() => {history.push('/servicios')}}
          >
            <Grid container item direction="row" alignItems="center" justifyContent="center">
              servicios
            </Grid>
          </Paper>
        </Grid>
        <LoginButton />
      </Grid>
    </Grid>
	);
});

export default HomePage;