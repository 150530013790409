import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import './styles/root.css';
import styles from './styles/SelectMulti.module.css';

const TXMSelect = observer(({name, options, defaultValue, action, label}) => {

	const customStyles = {
	  control: base => ({
	    ...base,
	    minHeight: 55,
	  })
	};

	// formatting options
	let selectOptions = [];

	// sort here

	for (const option of options) {
		console.log(option)
		selectOptions.push({
			value: option,
			label: option,
		});
	}

	let value = {value: defaultValue, label: defaultValue};

	function handleChange(selectedOption) {
		action(selectedOption.value);
	}

  return(
  	<div className='rootContainer'>
      <div className='labelContainer'>
        <div>
          {label}
        </div>
      </div>
			<div className={styles.selectContainer}>
		  	<Select
					size="large"
					name={name}
					options={selectOptions}
					value={value}
					onChange={handleChange}
					classNamePrefix="select"
					styles={customStyles}
		  	/>
	  	</div>
  	</div>
  );
});

export default TXMSelect;