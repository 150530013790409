import React from 'react'
import Select from 'react-select'
import { observer } from 'mobx-react'
import './styles/root.css';
// import styles from './styles/SelectMulti.module.css';
import Switch from '@material-ui/core/Switch';

const TXMSwitch = observer(({label, value, onChange}) => {

	const customStyles = {
	  control: base => ({
	    ...base,
	    minHeight: 55,
	  })
	};

  return(
  	<div className='rootContainer'>
      <div className='labelContainer'>
        <div>
          {label}
        </div>
      </div>
			<div>
	      <Switch
	        checked={value}
	        onChange={() => onChange(!value)}
	      />
	  	</div>
  	</div>
  );
});

export default TXMSwitch;