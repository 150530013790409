import React from 'react'
import SelectMulti from 'react-select'
import { observer } from 'mobx-react'
import './styles/root.css';
import styles from './styles/SelectMulti.module.css';

const TXMSelectMulti = observer(({name, options, defaultValue, action, label}) => {

	const customStyles = {
	  control: base => ({
	    ...base,
	    minHeight: 55,
	  })
	};

	// formatting options
	let selectOptions = [];
	for (const option of options) {
		selectOptions.push({
			value: option,
			label: option,
		});
	}

	let values = [];
	if(defaultValue) {
		for (const value of defaultValue) {
			values.push({
				value: value,
				label: value,
			})
		}
	}

	function handleChange(selectedOptions) {
		let newOptions = [];
		for (const option of selectedOptions) {
			newOptions.push(option.value)
		}
		action(newOptions);
	}

  return(
  	<div className='rootContainer'>
      <div className='labelContainer'>
        <div>
          {label}
        </div>
      </div>
			<div className={styles.selectContainer}>
		  	<SelectMulti
					isMulti
					size="large"
					name={name}
					options={selectOptions}
					value={values}
					onChange={handleChange}
					classNamePrefix="select"
					styles={customStyles}
		  	/>
	  	</div>
  	</div>
  );
});

export default TXMSelectMulti;