import React from 'react';

import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import TextField from '../../components/TextField'
import { useStore } from '../../stores/adminStore';
import { useOptionsStore } from '../../stores/optionsStore';
import Options from '../../utils/Options';
import SelectCreatable from '../../components/SelectCreatable';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
    padding: 20,
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
  },
  tall: {
    padding: 30
  },
  paper: {
    margin: 25,
    textAlign: 'center',
  },
  padding: {
    padding: 20
  },
  paper: {
    padding: 10,
    backgroundColor: '#f1f3f3'
  },
}));

const optionsPage = observer(() => {

  const adminStore = useStore();
  const optionsStore = useOptionsStore();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid 
      container 
      direction='row'
      alignItems='center'
      justifyContent='space-around'
      className={classes.tall}
    >

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container direction='column' spacing={1}>
            <Grid item xs={12}>
              <Typography variant='body2' gutterBottom className={classes.title}>
                En esta sección puedes definir las opciones disponibles para cada menú desplegable
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom className={classes.title}>
          Dirección
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.address.state}
            action={(state) => {optionsStore.setState(state)}}
            label='Estado'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.address.city}
            action={(city) => {optionsStore.setCity(city)}}
            label='Ciudad'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.address.municipality}
            action={(municipality) => {optionsStore.setMunicipality(municipality)}}
            label='Municipio'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.address.neighbourhood}
            action={(neighbourhood) => {optionsStore.setNeighbourhood(neighbourhood)}}
            label='Sector/Urbanización'
          />
        </Grid>
      </Grid>



      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          Servicios
        </Typography>
      </Grid>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.name}
            action={(names) => {optionsStore.setServiceNames(names)}}
            label='Nombre'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.specialServices}
            action={(specialServices) => {optionsStore.setSpecialServices(specialServices)}}
            label='Servicios especiales'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.appointments}
            action={(appointments) => {optionsStore.setAppointments(appointments)}}
            label='Método de atención'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.resultTypes}
            action={(resultTypes) => {optionsStore.setResultTypes(resultTypes)}}
            label='Método de entrega de resultados'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.paymentTypes}
            action={(paymentTypes) => {optionsStore.setPaymentTypes(paymentTypes)}}
            label='Métodos de pago'
          />
        </Grid>

        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.insuranceCompanies}
            action={(insuranceCompanies) => {optionsStore.setInsuranceCompanies(insuranceCompanies)}}
            label='Aseguradoras'
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          Exámenes
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.service.exams}
            action={(exams) => {optionsStore.setExams(exams)}}
            label='Examen'
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          Clientes
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectCreatable
            values={optionsStore.options.client.institutionType}
            action={(institutionTypes) => {optionsStore.setInstitutionTypes(institutionTypes)}}
            label='Tipo de institución'
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          className={classes.button}
          variant="contained" 
          color="primary"
          onClick={() => { optionsStore.saveOptions() }}
        >
          Guardar
        </Button>
      </Grid>


    </Grid>
  );

});

export default optionsPage;