import React from 'react';
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField'
import { useStore } from "../../stores/adminStore";
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Canvas from '../../components/Canvas';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Manrope',
    padding: 20,
  },
  button: {
    backgroundColor: '#859900',
    '&:hover': {
      backgroundColor: "#778900",
    },
    color: 'white',
  },
  tall: {
    // height: '80vh',
    padding: 30
  },
  paper: {
    margin: 25,
    textAlign: 'center',
  },
  padding: {
    padding: 20
  }
}));

const branchDetailPage = observer(() => {

  const adminStore = useStore();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid 
      container 
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      className={classes.tall}
    >
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          Clientes ({adminStore.clientsCount})
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.clientsToCheckCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.clientsToCheckCount * 100) / adminStore.clientsCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por revisar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.clientsToApproveCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.clientsToApproveCount * 100) / adminStore.clientsCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por aprobar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.clientsApprovedCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.clientsApprovedCount * 100) / adminStore.clientsCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              aprobados
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          Sedes ({adminStore.branchesCount})
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.branchesToCheckCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.branchesToCheckCount * 100) / adminStore.branchesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por revisar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.branchesToApproveCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.branchesToApproveCount * 100) / adminStore.branchesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por aprobar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.branchesApprovedCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.branchesApprovedCount * 100) / adminStore.branchesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              aprobados
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>



      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          Servicios ({adminStore.servicesCount})
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.servicesToCheckCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.servicesToCheckCount * 100) / adminStore.servicesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por revisar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.servicesToApproveCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.servicesToApproveCount * 100) / adminStore.servicesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              por aprobar
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper 
          elevation={3} 
          className={classes.paper}
        >
          <div
            className={classes.padding}
          >
            <Typography variant="h2" gutterBottom className={classes.title}>
              {adminStore.servicesApprovedCount}
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.title}>
              ({Math.round((adminStore.servicesApprovedCount * 100) / adminStore.servicesCount)}%)
            </Typography>
            <Typography variant="small" gutterBottom className={classes.title}>
              aprobados
            </Typography>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12}>
        <Canvas/>
      </Grid>
    </Grid>
  );

});

export default branchDetailPage