import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let apiUrl = 'https://adminapi.tuexamenmedico.com/branches';

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/branches';
} else {
	// apiUrl = 'http://localhost:3002/branches';
}

export default class branchStore {
	id = '';
	saved = true;
	loading = false;
	success = false;
	successMessage = '';

	branch = {
		name: '',
		address: {
			city: '',
			state: '',
			municipality: '',
			neighbourhood: '',
			address: '',
		},
		client: {
			_id: '',
			businessName: '',
			tradeName: '',
		},
		services: [],
		contact: {
			phones: null,
			cellPhones: null,
			email: null,
			whatsapp: null,
			website:null,
			instagram: null,
			twitter: null,
			facebook: null,
			youtube: null
		},
		hasParking: null,
		notes: {},
		status: null,
	};

	// data about the search performed by branch
	branchSearch = {
		searchTerm: '',
		results: [],
		count: 0,
	};
	
	constructor() {
		makeAutoObservable(this);
	}

	async clear() {
		runInAction(() => {
			this.branch = {
				name: '',
				address: {
					city: '',
					state: '',
					municipality: '',
					neighbourhood: '',
					address: '',
				},
				client: {
					_id: '',
					businessName: '',
					tradeName: '',
				},
				services: [],
				contact: {
					phones: null,
					cellPhones: null,
					email: null,
					whatsapp: null,
					website:null,
					instagram: null,
					twitter: null,
					facebook: null,
					youtube: null
				},
				hasParking: null,
				notes: {},
				status: null,
			};
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		API related functions
	*/

	async getAll() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/');
		const branchDocs = await response.json();

		runInAction(() => {
			this.branchSearch.results = branchDocs;
			this.branchSearch.count = branchDocs.length;
		});

		this.loading = false;
	}

  async getOne(branchId) {
  	this.loading = true;
		const response = await fetch(`${apiUrl}/${branchId}`);

		const branchDoc = await response.json();

		runInAction(() => {
			this.id = branchDoc._id;
			this.branch = branchDoc;
		});
		this.loading = false;
  }

  async getNext(code) {
  	const nextCode = code + 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const branchDoc = await response.json();

  	runInAction(() => {
  		this.id = branchDoc._id;
  		this.branch = branchDoc;
  	});
  }

  async getPrevious(code) {
  	const nextCode = code - 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const branchDoc = await response.json();

  	runInAction(() => {
  		this.id = branchDoc._id;
  		this.branch = branchDoc;
  	});
  }

	async deleteOne() {
  	this.loading = true;

		const response = await fetch(`${apiUrl}/${this.id}`, {
		  method: 'DELETE',
		});

		const r = await response.json();

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La institucion fue borrada exitosamente'

		return r;
	}

	async saveBranch() {

  	//FIXME refactor into async calls
  	this.loading = true;

		await fetch(apiUrl + '/' + this.id, {
		  method: 'PATCH',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.branch),
		});

		// const message = await response.json();

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'
	}

	async insertOne() {

  	//FIXME refactor into async calls
  	this.loading = true;

		const response = await fetch(apiUrl + '/', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.branch),
		});

		const branchId = await response.json();

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'

		return branchId;
	}


	/* 
		Setters
	*/
	setName(name) {
		this.branch.name = name;
	}

	setClient(client) {
		this.branch.client = client;
	}

	setAddress(address) {
		this.branch.address.address = address;
	}

	setState(state) {
		this.branch.address.state = state;
	}

	setCity(city) {
		this.branch.address.city = city;
	}

	setMunicipality(municipality) {
		this.branch.address.municipality = municipality;
	}

	setNeighbourhood(neighbourhood) {
		this.branch.address.neighbourhood = neighbourhood;
	}

	setPhones(phones) {
		this.branch.contact.phones = phones;
	}

	setCellPhones(cellPhones) {
		this.branch.contact.cellPhones = cellPhones;
	}

	setWhatsapp(whatsapp) {
		this.branch.contact.whatsapp = whatsapp;
	}

	setEmail(email) {
		this.branch.contact.email = email;
	}

	setWebsite(website) {
		this.branch.contact.website = website;
	}

	setFacebook(facebook) {
		this.branch.contact.facebook = facebook;
	}

	setYouTube(youTube) {
		this.branch.contact.youtube = youTube;
	}

	setInstagram(instagram) {
		this.branch.contact.instagram = instagram;
	}

	setTwitter(twitter) {
		this.branch.contact.twitter = twitter;
	}

	setServiceTypes(serviceTypes) {
		this.branch.serviceTypes = serviceTypes;
	}

	setSearchTerm(searchTerm) {
		this.branchSearch.searchTerm = searchTerm;
	}

	setSuccess(success, message) {
		this.success = success;
		this.successMessage = message;
	}

	setHasParking(hasParking) {
		this.branch.hasParking = hasParking;
	}

	setHasParkingNotes(notes) {
		this.branch.notes.hasParking = notes;
	}

	setAdminNotes(notes) {
		this.branch.notes.admin = notes;
	}

	setStatus(status) {
		this.branch.status = status;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const BranchStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the branch store in any functional component */
export const useStore = () => React.useContext(StoreContext);