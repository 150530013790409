import './App.css';

import { BrowserRouter as Router } from "react-router-dom";
import Routes from './Routes';
import AppBar from './components/AppBar';
import Drawer from './components/drawer/Drawer';
import MobileDrawer from './components/drawer/MobileDrawer';
// import LoadingProgress from './components/util/LoadingProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

import ClientStore, { ClientStoreProvider } from './stores/clientStore';
import BranchStore, { BranchStoreProvider } from './stores/branchStore';
import ServiceStore, { ServiceStoreProvider } from './stores/serviceStore';
import AdminStore, { AdminStoreProvider } from './stores/adminStore';
import OptionsStore, { OptionsStoreProvider } from './stores/optionsStore';
import ContentGeneratorStore, { ContentGeneratorStoreProvider } from './stores/contentGeneratorStore';
import { useAuth0 } from "@auth0/auth0-react";

import LoginPage from './pages/LoginPage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // minWidth: '100vh',
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
  },
  main: {
    marginLeft: 230,
    marginTop: 60,
    flex: '1'
  }
}));

const clientStore = new ClientStore();
const branchStore = new BranchStore();
const serviceStore = new ServiceStore();
const adminStore = new AdminStore();
const optionsStore = new OptionsStore();
const contentGeneratorStore = new ContentGeneratorStore();

function App() {
  const classes = useStyles();
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    console.log(window.location.origin)

    console.log(isAuthenticated)

    if(user)
      console.log(user.name)

    // if (!isAuthenticated)
    //   loginWithRedirect();

  });

  return (
    <Router>
    <div className={classes.root}>
        <ClientStoreProvider store={clientStore}>
        <BranchStoreProvider store={branchStore}>
        <ServiceStoreProvider store={serviceStore}>
        <AdminStoreProvider store={adminStore}>
        <OptionsStoreProvider store={optionsStore}>
        <ContentGeneratorStoreProvider store={contentGeneratorStore}>

        {isAuthenticated ? (
        <>
          <AppBar />
          <Drawer />
          <MobileDrawer />
          <main className={classes.main}>
            <Routes/>
          </main>
        </>
          ) : (<LoginPage/>)}
        </ContentGeneratorStoreProvider>
        </OptionsStoreProvider>
        </AdminStoreProvider>
        </ServiceStoreProvider>
        </BranchStoreProvider>
        </ClientStoreProvider>
    </div>
    </Router>
  );
}

export default App;
