import React from 'react';
import { observer } from 'mobx-react';
import { useStore as useBranchStore } from '../../stores/branchStore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import Radio from '../../components/Radio';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import Link from '../../components/Link';

const useStyles = makeStyles((theme) => ({
    title: { //TODO find a way to make this global
      fontFamily: 'Manrope',
    },
}));
  
const GeneralInformationForm = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  let branchStore = useBranchStore();

  // TODO: this is behaving weird.
  const handleChange = (event) => {
    let hasParking = event.target.value === 'false' ? false : true;
    branchStore.setHasParking(hasParking);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Informacion General
          </Typography>
        </Grid>

        {/* Nombre */}
        <Grid item xs={12}>
          <TextField
            value={branchStore.branch.name}
            onChange={(name) => {branchStore.setName(name)}}
            label='Nombre'
          />
        </Grid>

        {/* Facilidad para estacionar */}
        <Grid item xs={12}>
          <Radio 
            label='Facilidad para estacionar'
            labelFalse='No'
            labelTrue='Si'
            value={branchStore.branch.hasParking} // TODO: fix this
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        {/* Observaciones de estacionamiento */}
        <Grid item xs={12}>
            <TextArea
              label='Observaciones'
              value={branchStore.branch.notes.hasParking}
              action={(notes) => {branchStore.setHasParkingNotes(notes)}}
            />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            Cliente
          </Typography>
        </Grid>

        <Grid item xs={12}>
        
          <TextField
            value={branchStore.branch.client.businessName}
            label='Nombre Fiscal'
            disabled={true}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <Link 
            href={`/clientes/detalles/${branchStore.branch.client._id}`}
            value={branchStore.branch.client.tradeName}
            label='Nombre Comercial'
          />
        </Grid>

      </Grid>
    </div>
  )
})

export default GeneralInformationForm;