import React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useStore } from '../stores/clientStore';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  manrope: {
    fontFamily: 'manrope',
    borderStyle: 'none',
  },
  test: {
    backgroundColor: 'pink'
  },
  root: {
    '& .super-app-theme--header': {
      fontSize: 16,
    },
  },
}));

const columns = [
  { 
    field: 'code', 
    headerName: 'Codigo', 
    flex: 1, 
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip 
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'status', headerName: 'Estatus', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'stage', headerName: 'Etapa de venta', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'tradeName', headerName: 'Nombre Comercial', flex: 2, headerClassName: 'super-app-theme--header' },
  // { field: 'businessName', headerName: 'Nombre Fiscal', flex: 2, headerClassName: 'super-app-theme--header' },
  // { field: 'rif', headerName: 'RIF', flex: 2, headerClassName: 'super-app-theme--header' },
  { field: 'institutionTypes', headerName: 'Tipo de Institucion', flex: 4, headerClassName: 'super-app-theme--header' },
  { field: 'state', headerName: 'Estado', flex: 2, headerClassName: 'super-app-theme--header' },
];

const mobileColumns = [
  { 
    field: 'code',
    headerName: 'Codigo',
    flex: 2,
    headerClassName: 'super-app-theme--header',
    renderCell: (params: GridCellParams) => (
      <Chip
        size='small'
        label={params.value}
        color='secondary'
        variant='outlined'
      />
    ),
  },
  { field: 'businessName', headerName: 'Nombre Comercial', flex: 5, headerClassName: 'super-app-theme--header' },
];

const clientTable = observer(() => {
  
  const clientStore = useStore();
  let history = useHistory();
  const classes = useStyles();
  let rows = [];

  if (clientStore.clientSearch.results.length) {
    for (const clientDoc of clientStore.clientSearch.results) {

      let institutionTypes = '';

      for (const institutionType of clientDoc.institutionTypes) {
        institutionTypes += ` ● ${institutionType} `;
      }

      let row = {
        id: clientDoc._id,
        code: `CL-${clientDoc.code}`,
        // businessName: clientDoc.businessName,
        tradeName: clientDoc.tradeName,
        // rif: clientDoc.rif,
        institutionTypes: institutionTypes,
        state: clientDoc.address.state,
        status: clientDoc.status,
        stage: clientDoc.stage,
      };
      rows.push(row);
    }
  }

  useEffect(async () => {
    clientStore.getAll();
  }, []);

  return (
    <div className={classes.root}>

    {/*Desktop grid*/}
    <Hidden smDown implementation="css">
      <DataGrid
        rows={rows}
        columns={columns}
        direction="column"
        pageSize={20}
        density='standard' // or 'comfortable'
        autoHeight={true}
        localeText={esES.props.MuiDataGrid.localeText}
        className={classes.manrope}
        onRowClick={async (event) => {
          history.push(`/clientes/detalles/${event.id}`);
        }}
      />
    </Hidden>


    <Hidden mdUp implementation="css">
      <DataGrid 
        rows={rows} 
        columns={mobileColumns}
        direction="column"
        pageSize={20}
        density='compact' // or compact or 
        // autoHeight={true}
        className={classes.manrope}
        onRowClick={async (event) => {
          await clientStore.clear();
          await clientStore.getOne(event.id);
          history.push('/clientes/detalles');
        }}
      />
    </Hidden>
    </div>
  );
});

export default clientTable;