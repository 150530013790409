import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '100%'
  },
	container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'space-evenly',
	},
  rowContainer: {
  	display: 'flex',
  	flexDirection: 'row',
  	alignItems: 'center',
  	// padding: 6,
  },
	timePickerLabel: {
		width: 50,
	},
  timePickerlabelContainer: { // find better values
    minWidth: 100,
    flexGrow: 1,
  },
  timePickerContainer: {
    flexGrow: 2,
  },
  labelContainer: { // find better values
    maxWidth: 170, 
    flexGrow: 1,
  },
  label: {
    fontFamily: 'Manrope',
    padding: 25,
  },
  separatorLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 8,
    paddingLeft: 8,
  },
}));

const TXMTimePicker = observer(({ label, schedule, setSchedule }) => {
	const classes = useStyles();

	if (!schedule || schedule == {}) {
		schedule = {
			monday: [null,null],
			tuesday: [null,null],
			wednesday: [null,null],
			thursday: [null,null],
			friday: [null,null],
			saturday: [null,null],
			sunday: [null,null],
		}
	}

	// this can stay hardcoded in the file
	const days = [
		{
			label: 'Lunes',
			value: 'monday',
		},
		{
			label: 'Martes',
			value: 'tuesday',
		},
		{
			label: 'Miercoles',
			value: 'wednesday',
		},
		{
			label: 'Jueves',
			value: 'thursday',
		},
		{
			label: 'Viernes',
			value: 'friday',
		},
		{
			label: 'Sabado',
			value: 'saturday',
		},
		{
			label: 'Domingo',
			value: 'sunday',
		}
	];

	function handleChange(event, isOpen) {

		let sch = {
			monday: [schedule.monday[0] || null, schedule.monday[1] || null],
			tuesday: [schedule.tuesday[0] || null, schedule.tuesday[1] || null],
			wednesday: [schedule.wednesday[0] || null, schedule.wednesday[1] || null],
			thursday: [schedule.thursday[0] || null, schedule.thursday[1] || null],
			friday: [schedule.friday[0] || null, schedule.friday[1] || null],
			saturday: [schedule.saturday[0] || null, schedule.saturday[1] || null],
			sunday: [schedule.sunday[0] || null, schedule.sunday[1] || null],
		};

		let openOrClose = event.target.id.split('-')[0]
		let day = event.target.id.split('-')[1]

		if(openOrClose === 'open') {
			sch[day][0] = event.target.value;
		} else if(openOrClose === 'close') {
			sch[day][1] = event.target.value;
		}
		
		setSchedule(sch);
	}

  return(
  <div className={classes.root}>
	  <div className={classes.labelContainer}>
	    <Typography className={classes.label}>
	      {label}
	    </Typography>
	  </div>
		<div className={classes.container}>
		{
		days.map(day => (
			<div className={classes.rowContainer}>
				<div className={classes.timePickerlabelContainer}>
					{day.label}
				</div>
				<div className={classes.timePickerContainer}>
					<TextField
						id={"open-"+day.value}
						className={classes.textField}
						type="time"
						defaultValue={schedule[day.value] ? schedule[day.value][0] : '' }
						InputLabelProps={{
						  shrink: true,
						}}
						inputProps={{
						  step: 300, // 5 min
						}}
						onChange={(event) => handleChange(event, true)}
						open="open"
					/>
				</div>
				<div className={classes.separatorLabel}>
					a
				</div>
				<div className={classes.timePickerContainer}>
						<TextField
				      id={"close-"+day.value}
				      className={classes.textField}
				      type="time"
				      defaultValue={schedule[day.value] ? schedule[day.value][1] : ''}
				      InputLabelProps={{
				        shrink: true,
				      }}
				      inputProps={{
				        step: 300, // 5 min
				        // endAdornment: '',
				      }}
				      onChange={(event) => handleChange(event, false)}
				      open="close"
						/>
				</div>
	    </div>
		))
	}
		</div>
</div>
  );
});

export default TXMTimePicker;