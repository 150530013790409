import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Chip from '@material-ui/core/Chip';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import GeneralInformationForm from '../../forms/services/GeneralInformation';
import ContactInformationForm from '../../forms/services/ContactDetails';
import ServicesForm from '../../forms/services/ServicesForm';
import ExamsForm from '../../forms/services/ExamsForm';
import AdminInformationForm from '../../forms/services/AdminInformationForm';
import SalesInformationForm from '../../forms/services/SalesInformationForm';
import { useStore as useServiceStore } from "../../stores/serviceStore";
import { useStore as useBranchStore } from "../../stores/branchStore";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';

//FIXME to be removed eventually
const useStyles = makeStyles((theme) => ({
  root: {
  	width: '100%',
  	display: 'flex',
  	flexDirection: 'column',
  	alignItems: 'center',
  	backgroundColor: 'pink',
  },
  button: {
  	margin: '50px',
  	background: '#268bd2'
  },
  title: {
  	fontFamily: 'Manrope',
  	paddingTop: 20
  },
  tabs: {
  	backgroundColor: '#268bd2',
  },
  mainContainer: {
  	width: '90%',
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: '10px',
    },
  },
	deleteButton: {
		backgroundColor: '#dc322f',
		'&:hover': {
      backgroundColor: "#c62d2a",
    }
	},
	appBarContainer: {
		paddingBottom: '20px',
		width: '80vw'
	},
	tall: {
		minHeight: '50vh',
	}
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ServiceDetailPage = observer(() => {

	const serviceStore = useServiceStore();
	const branchStore = useBranchStore();
	const classes = useStyles();
	const params = useParams();

  useEffect(() => {
  	serviceStore.clear();
    serviceStore.getOne(params.id);
  }, []);

	const [selectedTab, setSelectedTab] = React.useState(0);

  const handleGetNext = async () => {
		await serviceStore.getNext(serviceStore.service.code);
  }
  const handleGetPrevious = async () => {
  	await serviceStore.getPrevious(serviceStore.service.code);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    serviceStore.setSuccess(false, '');
  };

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const history = useHistory();

  if (!serviceStore.service) {
  	serviceStore.service = {};
  }

  function DetailPage() {
	  return(
			<Grid 
						container 
						spacing={2}
						direction="column"
						alignItems="center"
						justifyContent="center"
			  	>
				  	<Grid 
				  		container 
				  		direction="column"
							alignItems="center"
							justifyContent="center"
				  	>
				  		{/* Title */}
				  		<Grid container alignItems='baseline' justifyContent='space-between' spacing={2} wrap='nowrap' xs={11}>
				  			<Grid item>
									<IconButton onClick={handleGetPrevious}>
									  <NavigateBeforeIcon />
									</IconButton>
				  			</Grid>
								<Grid item>
									<Typography variant="h5" gutterBottom className={classes.title}>
										{serviceStore.service.name}
									</Typography>
								</Grid>
								<Grid item>
									<IconButton onClick={handleGetNext}>
									  <NavigateNextIcon />
									</IconButton>
								</Grid>
							</Grid>

						{/* Chip */}
							<Grid item>
								<Chip 
									label={`SR-${serviceStore.service.code}`}
									size='small'
									color='secondary'
									variant='outlined'
								/>
							</Grid>

						</Grid>
				      
						{/* Tabs */}
						<Grid item xs={11}>
							<div className={classes.appBarContainer}>
							  <AppBar position="static" className={classes.tabs}>
							    <Tabs
							      value={selectedTab} 
							      onChange={handleTabChange} 
							      indicatorColor="primary"
							      variant="scrollable"
							    >
							      <Tab label="Informacion General" />
							      <Tab label="Datos de Contacto" />
							      <Tab label="Atencion al Paciente" />
							      <Tab label="Examenes" />
							      <Tab label="Admin" />
							      <Tab label="Ventas" />
							    </Tabs>
							  </AppBar>
							</div>
							<div className={classes.tall}>
							  {selectedTab === 0 && <GeneralInformationForm/>}
							  {selectedTab === 1 && <ContactInformationForm/>}
							  {selectedTab === 2 && <ServicesForm/>}
							  {selectedTab === 3 && <ExamsForm/>}
							  {selectedTab === 4 && <AdminInformationForm/>}
							  {selectedTab === 5 && <SalesInformationForm/>}
						  </div>
						</Grid>

						{/* Save button */}
						<Grid item xs={10}>
							<Button
								className={classes.button}
								variant="contained" 
								color="primary"
								onClick={() => { serviceStore.saveService() }}
							>
								Guardar
							</Button>
						</Grid>
							<div className={classes.snack}>
								<Snackbar open={serviceStore.success} autoHideDuration={4000} onClose={handleClose}>
				        	<Alert onClose={handleClose} variant="filled" severity="success">
					        	{serviceStore.successMessage}
					        </Alert>
				      	</Snackbar>
			      	</div>
					</Grid>
		)
  };

  function LoadingOverlay() {
  	return (
  		<Grid 
  			container
				direction="column"
				alignItems="center"
				justifyContent="center"
				className={classes.tall}
			>
  			<Grid item>
  				<CircularProgress/>
  			</Grid>
  		</Grid>
  	)
  }

	return DetailPage();

});

export default ServiceDetailPage