import React from "react"
import { observer } from 'mobx-react'
import BranchTable from '../../components/BranchTable';
import Grid from '@material-ui/core/Grid';

const InstitutionListPage = observer(() => {
	return (
		<Grid 
			container 
			spacing={3}
			direction="row"
			alignItems="center"
			justifyContent="center"
  	>
			{/* Table */}
			 <Grid item xs={11}/>
			<Grid item xs={11}/>
			<Grid item xs={11}>
				<BranchTable />
			</Grid>
		</Grid>
	)
})

export default InstitutionListPage;