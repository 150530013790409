import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let clientsApiUrl = 'https://adminapi.tuexamenmedico.com/clients';
let branchesApiUrl = 'https://adminapi.tuexamenmedico.com/branches';
let servicesApiUrl = 'https://adminapi.tuexamenmedico.com/services';

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/clients';
} else {
	// apiUrl = 'http://localhost:3002/clients';
}

export default class adminStore {

	clientsCount = 0;
	clientsToCheckCount = 0;
	clientsToApproveCount = 0;
	clientsApprovedCount = 0;

	branchesCount = 0;
	branchesToCheckCount = 0;
	branchesToApproveCount = 0;
	branchesApprovedCount = 0;

	servicesCount = 0;
	servicesToCheckCount = 0;
	servicesToApproveCount = 0;
	servicesApprovedCount = 0;
	
	constructor() {
		makeAutoObservable(this);
		this.getStats();
	}

	async clear() {
		runInAction(() => {
			this.client = {
				businessName: '',
				tradeName: '',
				isPrivate: null,
				rif: '',
				logo: '',
				institutionTypes: [],
				branches: [],
				address: {},
				contact: {
					phones: null,
					cellPhones: null,
					email: null,
					whatsapp: null,
					website:null,
					instagram: null,
					twitter: null,
					facebook: null,
					youtube: null
				},
				contactPerson: {
					name: '',
					phoneNumbers: [],
					whatsapp: [],
					rank: '',
					email: '',
				},
				notes: {},
				status: null,
			};
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		API related functions
	*/

	async getStats() {
		this.loading = true;

		// get four counts from client
		let response;

		// clients
		response = await fetch(clientsApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por revisar',
		}));
		const ctc = await response.json();

		response = await fetch(clientsApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por aprobar',
		}));
		const cta = await response.json();

		response = await fetch(clientsApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Aprobado',
		}));
		const ca = await response.json();

		response = await fetch(clientsApiUrl  + '/count');
		const c = await response.json();


		// branches
		response = await fetch(branchesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por revisar',
		}));
		const btc = await response.json();

		response = await fetch(branchesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por aprobar',
		}));
		const bta = await response.json();

		response = await fetch(branchesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Aprobado',
		}));
		const ba = await response.json();

		response = await fetch(branchesApiUrl  + '/count');
		const b = await response.json();



		// services
		response = await fetch(servicesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por revisar',
		}));
		const stc = await response.json();

		response = await fetch(servicesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Por aprobar',
		}));
		const sta = await response.json();

		response = await fetch(servicesApiUrl  + '/count?' + new URLSearchParams({
    	status: 'Aprobado',
		}));
		const sa = await response.json();

		response = await fetch(servicesApiUrl  + '/count');
		const s = await response.json();

		runInAction(() => {
			this.clientsCount = c.count;
			this.clientsToCheckCount = ctc.count;
			this.clientsToApproveCount = cta.count;
			this.clientsApprovedCount = ca.count;

			this.branchesCount = b.count;
			this.branchesToCheckCount = btc.count;
			this.branchesToApproveCount = bta.count;
			this.branchesApprovedCount = ba.count;

			this.servicesCount = s.count;
			this.servicesToCheckCount = stc.count;
			this.servicesToApproveCount = sta.count;
			this.servicesApprovedCount = sa.count;
		});

		this.loading = false;
	}


	/* 
		Setters
	*/
	setBusinessName(businessName) {
		this.client.businessName = businessName;
		this.saved = false;
	}

	setRif(rif) {
		this.client.rif = rif;
		this.saved = false;
	}

	setCreatedBy(createdBy) {
		this.client.createdBy = createdBy;
		this.saved = false;
	}

	setTradeName(tradeName) {
		this.client.tradeName = tradeName;
		this.saved = false;
	}

	setNumberOfBranches(numberOfBranches) {
		this.client.numberOfBranches = numberOfBranches;
		this.saved = false;
	}

	setAddress(address) {
		this.client.address.address = address;
		this.saved = false;
	}

	setState(state) {
		this.client.address.state = state;
		this.saved = false;
	}

	setCity(city) {
		this.client.address.city = city;
		this.saved = false;
	}

	setMunicipality(municipality) {
		this.client.address.municipality = municipality;
		this.saved = false;
	}

	setNeighbourhood(neighbourhood) {
		this.client.address.neighbourhood = neighbourhood;
		this.saved = false;
	}

	setPhones(phones) {
		this.client.contact.phones = phones;
		this.saved = false;
	}

	setCellPhones(cellPhones) {
		this.client.contact.cellPhones = cellPhones;
		this.saved = false;
	}

	setWhatsapp(whatsapp) {
		this.client.contact.whatsapp = whatsapp;
		this.saved = false;
	}

	setEmail(email) {
		this.client.contact.email = email;
		this.saved = false;
	}

	setWebsite(website) {
		this.client.contact.website = website;
		this.saved = false;
	}

	setFacebook(facebook) {
		this.client.contact.facebook = facebook;
		this.saved = false;
	}

	setYouTube(youTube) {
		this.client.contact.youtube = youTube;
		this.saved = false;
	}

	setInstagram(instagram) {
		this.client.contact.instagram = instagram;
		this.saved = false;
	}

	setTwitter(twitter) {
		this.client.contact.twitter = twitter;
		this.saved = false;
	}

	setServiceTypes(serviceTypes) {
		this.client.serviceTypes = serviceTypes;
		this.saved = false;
	}

	setSearchTerm(searchTerm) {
		this.clientSearch.searchTerm = searchTerm;
	}

	setSuccess(success, message) {
		this.success = success;
		this.successMessage = message;
	}

	setIsPrivate(isPrivate) {
		this.client.isPrivate = isPrivate;
	}

	setHasParking(hasParking) {
		this.client.hasParking = hasParking;
		this.saved = false;
	}

	setHasParkingNotes(notes) {
		this.client.notes.hasParking = notes;
		this.saved = false;
	}

	setContactPersonRank(rank) {
		this.client.contactPerson.rank = rank;
		this.saved = false;
	}

	setContactPersonEmail(email) {
		this.client.contactPerson.email = email;
		this.saved = false;
	}

	setContactPersonPhones(phoneNumbers) {
		this.client.contactPerson.phoneNumbers = phoneNumbers;
		this.saved = false;
	}

	setContactPersonWhatsapp(whatsapp) {
		this.client.contactPerson.whatsapp = whatsapp;
		this.saved = false;
	}

	setContactPersonName(name) {
		this.client.contactPerson.name = name;
		this.saved = false;
	}

	setInstitutionTypes(institutionTypes) {
		this.client.institutionTypes = institutionTypes;
		this.saved = false;
	}

	setStatus(status) {
		this.client.status = status;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const AdminStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the client store in any functional component */
export const useStore = () => React.useContext(StoreContext);