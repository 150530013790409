import React from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

let apiUrl = 'https://adminapi.tuexamenmedico.com/clients';

//TODO move this to a util function
if(window.location.href.startsWith('https://admin.tuexamenmedico.com/')) {
	// apiUrl = 'https://api.tuexamenmedico.com/clients';
} else {
	// apiUrl = 'http://localhost:3002/clients';
}

export default class clientStore {
	id = '';
	saved = true;
	loading = false;
	success = false;
	successMessage = '';

	client = {
		businessName: '',
		tradeName: '',
		address: {
			address: '',
			neighbourhood: '',
			city: '',
			state: '',
			municipality: '',
		},
		isPrivate: null,
		rif: '',
		logo: '',
		institutionTypes: [],
		branches: [],
		numberOfBranches: null,
		contact: {
			phones: null,
			cellPhones: null,
			email: null,
			whatsapp: null,
			website:null,
			instagram: null,
			twitter: null,
			facebook: null,
			youtube: null
		},
		contactPerson: {
			name: '',
			phoneNumbers: [],
			whatsapp: [],
			rank: '',
			email: '',
		},
		notes: {
			admin: '',
		},
		status: null,
		stage: null,
	};

	// data about the search performed by client
	clientSearch = {
		searchTerm: '',
		results: [],
		count: 0,
	};
	
	constructor() {
		makeAutoObservable(this);
	}

	async clear() {
		runInAction(() => {
			this.client = {
				businessName: '',
				tradeName: '',
				isPrivate: null,
				rif: '',
				logo: '',
				institutionTypes: [],
				branches: [],
				address: {},
				contact: {
					phones: null,
					cellPhones: null,
					email: null,
					whatsapp: null,
					website:null,
					instagram: null,
					twitter: null,
					facebook: null,
					youtube: null
				},
				contactPerson: {
					name: '',
					phoneNumbers: [],
					whatsapp: [],
					rank: '',
					email: '',
				},
				notes: {
					admin: ''
				},
				status: null,
				stage: null,
			};
			this.loading = false;
			this.saved = true;
			this.id = ''; // this needs to be deprecated
		});
	}

	/* 
		API related functions
	*/

	async getAll() {
		this.loading = true;
		const response = await fetch(apiUrl  + '/');
		const clientDocs = await response.json();

		runInAction(() => {
			this.clientSearch.results = clientDocs;
			this.clientSearch.count = clientDocs.length;
		});

		this.loading = false;
	}

  async getOne(clientId) {
		const response = await fetch(`${apiUrl}/${clientId}`);

		const clientDoc = await response.json();

		runInAction(() => {
			this.id = clientDoc._id;
			this.client = clientDoc;
		});
  }

  async getNext(code) {
  	const nextCode = code + 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const clientDoc = await response.json();

  	runInAction(() => {
  		this.id = clientDoc._id;
  		this.client = clientDoc;
  	});
  }

  async getPrevious(code) {
  	const nextCode = code - 1;

  	const response = await fetch(`${apiUrl}/code/${nextCode}`);

  	const clientDoc = await response.json();

  	runInAction(() => {
  		this.id = clientDoc._id;
  		this.client = clientDoc;
  	});
  }

	async deleteOne() {
  	this.loading = true;

		const response = await fetch(`${apiUrl}/${this.id}`, {
		  method: 'DELETE',
		});

		const r = await response.json();
		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La institucion fue borrada exitosamente'

		return r;
	}

	async saveClient() {

  	//FIXME refactor into async calls
  	this.loading = true;

		await fetch(apiUrl + '/' + this.id, {
		  method: 'PATCH',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.client),
		});

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'
	}

	async insertOne() {

  	//FIXME refactor into async calls
  	this.loading = true;

		const response = await fetch(apiUrl + '/', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(this.client),
		});

		const clientId = await response.json();

		this.saved = true; // this should be only if message comes in as 'true'
		this.loading = false;
		this.success = true;
		this.successMessage = 'La informacion fue guardada exitosamente'

		return clientId;
	}


	/* 
		Setters
	*/
	setBusinessName(businessName) {
		this.client.businessName = businessName;
		this.saved = false;
	}

	setRif(rif) {
		this.client.rif = rif;
		this.saved = false;
	}

	setCreatedBy(createdBy) {
		this.client.createdBy = createdBy;
		this.saved = false;
	}

	setTradeName(tradeName) {
		this.client.tradeName = tradeName;
		this.saved = false;
	}

	setNumberOfBranches(numberOfBranches) {
		this.client.numberOfBranches = numberOfBranches;
		this.saved = false;
	}

	setAddress(address) {
		this.client.address.address = address;
		this.saved = false;
	}

	setState(state) {
		this.client.address.state = state;
		this.saved = false;
	}

	setCity(city) {
		this.client.address.city = city;
		this.saved = false;
	}

	setMunicipality(municipality) {
		this.client.address.municipality = municipality;
		this.saved = false;
	}

	setNeighbourhood(neighbourhood) {
		this.client.address.neighbourhood = neighbourhood;
		this.saved = false;
	}

	setPhones(phones) {
		this.client.contact.phones = phones;
		this.saved = false;
	}

	setCellPhones(cellPhones) {
		this.client.contact.cellPhones = cellPhones;
		this.saved = false;
	}

	setWhatsapp(whatsapp) {
		this.client.contact.whatsapp = whatsapp;
		this.saved = false;
	}

	setEmail(email) {
		this.client.contact.email = email;
		this.saved = false;
	}

	setWebsite(website) {
		this.client.contact.website = website;
		this.saved = false;
	}

	setFacebook(facebook) {
		this.client.contact.facebook = facebook;
		this.saved = false;
	}

	setYouTube(youTube) {
		this.client.contact.youtube = youTube;
		this.saved = false;
	}

	setInstagram(instagram) {
		this.client.contact.instagram = instagram;
		this.saved = false;
	}

	setTwitter(twitter) {
		this.client.contact.twitter = twitter;
		this.saved = false;
	}

	setServiceTypes(serviceTypes) {
		this.client.serviceTypes = serviceTypes;
		this.saved = false;
	}

	setSearchTerm(searchTerm) {
		this.clientSearch.searchTerm = searchTerm;
	}

	setSuccess(success, message) {
		this.success = success;
		this.successMessage = message;
	}

	setIsPrivate(isPrivate) {
		this.client.isPrivate = isPrivate;
	}

	setHasParking(hasParking) {
		this.client.hasParking = hasParking;
		this.saved = false;
	}

	setHasParkingNotes(notes) {
		this.client.notes.hasParking = notes;
		this.saved = false;
	}

	setContactPersonRank(rank) {
		this.client.contactPerson.rank = rank;
		this.saved = false;
	}

	setContactPersonEmail(email) {
		this.client.contactPerson.email = email;
		this.saved = false;
	}

	setContactPersonPhones(phoneNumbers) {
		this.client.contactPerson.phoneNumbers = phoneNumbers;
		this.saved = false;
	}

	setContactPersonWhatsapp(whatsapp) {
		this.client.contactPerson.whatsapp = whatsapp;
		this.saved = false;
	}

	setContactPersonName(name) {
		this.client.contactPerson.name = name;
		this.saved = false;
	}

	setInstitutionTypes(institutionTypes) {
		this.client.institutionTypes = institutionTypes;
		this.saved = false;
	}

	setStatus(status) {
		this.client.status = status;
	}

	setStage(stage) {
		this.client.stage = stage;
	}

	setAdminNotes(notes) {
		this.client.notes.admin = notes;
	}
}


/* Store helpers */
const StoreContext = React.createContext();
 
export const ClientStoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* This hook allows us to use the client store in any functional component */
export const useStore = () => React.useContext(StoreContext);