import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { observer } from 'mobx-react'
import './styles/root.css';
import styles from './styles/TextField.module.css';

const TextField = observer(({ value, onChange, label, placeholder, disabled, variant }) => {
  return (
    <div className='rootContainer'> {/* these are global classes from root.css */}
      <div className='labelContainer'> {/* they can be reused in multiple components */}
        <div> {/* leaving this div without styles so we can apply custom visual styles */}
          { label }
        </div>
      </div>
      <div className={styles.textFieldContainer}> {/* classes only relevant to this component come from a CSS module */}
        <MuiTextField
          value={value || ''}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
          fullWidth
          variant={variant || "outlined"}
          disabled={disabled}
        />
      </div>
    </div>
  );
});

export default TextField;